import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useModel, useSelector} from 'umi';
import debounce from "lodash/debounce";

import MyPageContainer from "@/components/MyPageContainer";
import LeadKanbanBoard from "@/components/Kanban/Board";
import Card from "@/components/Card";
import LeadToolbar from "@/pages/Leads/LeadToolbar";
import {SOLUTION_KEYS} from "@/constants/solutions";
import * as LeadSelectors from "@/selectors/leadSelectors";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors";
import LeadTasksOverview from "@/components/Lead/LeadTasks/LeadTasksOverview";
import styles from "./index.less"
import LeadsTable from "@/components/Kanban/LeadsTable";

const TRACKING_LABEL = "Customers"
const REDUX_ACTION = 'leads/fetch'

const Leads: React.FC = () => {
  const {initialState, setInitialState} = useModel('@@initialState');

  const dispatch = useDispatch()

  const [tablePayload, setTablePayload] = useState({})
  const { leadKanbanView, filters, date, loading, list, statuses, groupedList, isHeatMap } =
    useSelector((state: DefaultRootState) => ({
      leadKanbanView: PreferencesSelectors.selectLeadKanbanView(state),
      isHeatMap: PreferencesSelectors.selectIsHeatMap(state),
      filters: LeadSelectors.selectLeadFilters(state),
      list: LeadSelectors.selectLeads(state),
      groupedList: LeadSelectors.selectGroupedLeads(state),
      statuses: LeadSelectors.selectStatusSettings(state),
      date: state.date,
      loading: state.loading.effects[REDUX_ACTION]
    }));

  const fetchTableData = useCallback(debounce((payload = tablePayload) => {
    setTablePayload(payload)
    dispatch({
      type: REDUX_ACTION,
      payload,
    });
  }, 200), [])

  const fetchKanbanData = useCallback(debounce((payload = tablePayload) => {
    setTablePayload(payload)
    dispatch({
      type: REDUX_ACTION,
      payload: {
        ...payload,
        group_by: 'lead_state',
      },
    });
  }, 200), [])
  const refresh = leadKanbanView ? fetchKanbanData : fetchTableData
  useEffect(() => {
    dispatch({
      type: 'preferences/fetchUiPreferences',
    })
    setInitialState({
      ...initialState,
      selectedSolution: SOLUTION_KEYS.LEAD_MANAGEMENT,
      hideFooter: true
    })

    dispatch({
      type: 'leads/fetchAssignees',
    })
    dispatch({
      type: 'leads/fetchLeadStatuses',
    });
    dispatch({
      type: 'user/getUserBranches',
    });

    setInterval(() => {
      // refresh()
    }, 1000)

    return () => {
      setInitialState({
        ...initialState,
        hideFooter: false
      })
    }
  }, []);
  useEffect(() => {
    refresh()
  }, [date.updatedAt, JSON.stringify(filters)])

  return (
    <MyPageContainer className={styles.leadsPageContainer} label={TRACKING_LABEL}
                     extraProps={{actions: [<LeadTasksOverview/>]}}>
      <Card>
        <LeadToolbar loading={loading} request={refresh}/>
        {leadKanbanView ? (
          <LeadKanbanBoard
            loading={loading}
            request={refresh}
            groupedLeads={groupedList?.data || []}
            columns={statuses}
          />
        ) : (
          <LeadsTable list={list} request={refresh} loading={loading}/>
        )}
      </Card>
    </MyPageContainer>
  );
};

export default Leads;
