import {Button, ButtonProps, Tooltip} from "antd";
import React from "react";

const LeadToolbarItem = ({tooltip, icon, onClick, children, buttonType, buttonProps = {}}: {
  onClick?: () => void,
  icon: React.ReactElement,
  buttonType?: string,
  tooltip: React.ReactElement
  children?: React.ReactElement
  buttonProps?: ButtonProps
}) => {
  return children || <Tooltip title={tooltip}>
    <Button type={buttonType} shape="round" icon={icon} onClick={onClick} {...buttonProps}/>
  </Tooltip>
}

export default LeadToolbarItem;
