import React, {useEffect, useState} from 'react';
import {Column} from '@ant-design/plots';
import {Card, Space, Tag} from 'antd';
import {selectDateFilter, useDispatch, useIntl, useModel, useSelector} from 'umi';

import {exportToCSV, transformLeadReportDataToBarChartFormat} from '../utils';
import MyPageContainer from '@/components/MyPageContainer';
import PerformanceChartToolbar from './PerformanceChartToolbar';
import LeadAssigneeDropdownFilters from '@/components/Filters/LeadAssigneeDropdownFilters';
import BranchDropdownFilters from '@/components/Filters/BranchDropdownFilters';
import LeadSourceDropdownFilters from '@/components/Filters/LeadSourceDropdownFilters';
import * as LeadSelectors from "@/selectors/leadSelectors";
import PerformanceTable from './PerforamnceDataTable';
import {
  ALL_METRICS,
  CONVERSION_RATE,
  GROUP_BY_ASSIGNEE,
  GROUP_BY_BRANCH,
  GROUP_BY_MARKETING_CHANNEL,
  GROUP_BY_STATUS,
  LEADS,
  REVENUE
} from '../constants';
import {SOLUTION_KEYS} from "@/constants/solutions";

const TRACKING_LABEL = "Performance Bar Chart";
const REDUX_ACTION = 'leads/fetchPerformanceChartData'
const columnColors = {
  [LEADS]: '#1890ff',
  [REVENUE]: '#52c41a',
  [CONVERSION_RATE]: '#f5222d',
};
const Dashboard = () => {
  const {initialState, setInitialState} = useModel('@@initialState');
  
  const [xAxis, setXAxis] = useState('branch');
  const [selectedColumns, setSelectedColumns] = useState(ALL_METRICS);
  const [chartData, setChartData] = useState([]);

  const { leadsReportData,loading,date } = useSelector(
    (state: DefaultRootState) => ({
      leadsReportData: LeadSelectors.selectPerformanceChartData(state),
      loading: state.loading.effects[REDUX_ACTION],
      date: selectDateFilter(state),
    }),
  );
    const dispatch = useDispatch();
    const intl = useIntl();


  useEffect(() => {
    if (leadsReportData?.length && leadsReportData[0]?.label) {
      const data = transformLeadReportDataToBarChartFormat(leadsReportData, xAxis);
      setChartData(data);
    }
  }, [JSON.stringify(leadsReportData)]);
  useEffect(() => {
    setInitialState({
      ...initialState,
      selectedSolution: SOLUTION_KEYS.LEAD_MANAGEMENT,
    })

  }, []);
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(selectedColumns), xAxis]);
  const fetchData = () => {
    dispatch({
      type: REDUX_ACTION,
      payload: { metrics: selectedColumns, group_by: xAxis },
    });
  };
  const addFiltersToState = (filters) => {
    dispatch({
      type: 'leads/setPerformanceChartFilters',
      payload: filters,
    });
  };
  const handleLeadSourceSelectionChange = (selectedValue) => {
    addFiltersToState({ leadSource: selectedValue });
    fetchData();
  };
  const handleMarketingChannelSelectionChange = (selectedValue) => {
    addFiltersToState({ marketingChannel: selectedValue });
    fetchData();
  };

  const handleLocationSelectionChange = (selectedValue) => {
    addFiltersToState({ location: selectedValue });
    fetchData();
  };
  const handleAssigneeSelectionChange = (selectedValue) => {
    addFiltersToState({ assignee: selectedValue });
    fetchData();
  };

  const config = {
    data: chartData ?? [],
    isGroup: true,
    xField: xAxis,
    yField: 'value',
    seriesField: 'category',
    color: ({ category }) => columnColors[category],
    label: {
      position: 'middle',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
      ],
    },
    interactions: [{ type: 'element-active' }],
  };

  const handleColumnChange = (value) => {
    setSelectedColumns(value);
  };

  const handleExport = () => {
    const headers =[xAxis, 'category', 'value']
    exportToCSV(headers, chartData, `leads-data-${date.start_date}_${date.end_date}.csv`);
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    return (
      <Tag
        color={columnColors[value]}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const metricOptions = [
    {
      value: LEADS,
      label: intl.formatMessage({ id: 'pages.leads.leads', defaultMessage: 'Leads' }),
    },
    {
      value: REVENUE,
      label: intl.formatMessage({ id: 'pages.leads.revenue', defaultMessage: 'Revenue' }),
    },
    {
      value: CONVERSION_RATE,
      label: intl.formatMessage({
        id: 'pages.leads.conversionRate',
        defaultMessage: 'Conversion Rate',
      }),
    },
  ];

  const groupByOptions = [
    {
      value: GROUP_BY_BRANCH,
      label: intl.formatMessage({
        id: 'pages.leads.branch',
        defaultMessage: 'Branch',
      }),
    },
    {
      value: GROUP_BY_STATUS,
      label: intl.formatMessage({
        id: 'pages.leads.lead_state',
        defaultMessage: 'Lead State',
      }),
    },
    // {
    //   value: GROUP_BY_SERVICE,
    //   label: intl.formatMessage({
    //     id: 'pages.leads.service',
    //     defaultMessage: 'Service',
    //   }),
    // },
    {
      value: GROUP_BY_MARKETING_CHANNEL,
      label: intl.formatMessage({
        id: 'pages.leads.marketingChannel',
        defaultMessage: 'Marketing Channel',
      }),
    },
    // {
    //   value: GROUP_BY_LOCATION,
    //   label: intl.formatMessage({
    //     id: 'pages.leads.location',
    //     defaultMessage: 'Location',
    //   }),
    // },
    {
      value: GROUP_BY_ASSIGNEE,
      label: intl.formatMessage({
        id: 'pages.leads.assignee',
        defaultMessage: 'Assignee',
      }),
    },
  ];

  return (
    <MyPageContainer
      label={TRACKING_LABEL}
      extraProps={{
        hasRangePicker: true,
      }}
    >
      <Card title="Filters" style={{ marginBottom: 16 }}>
        <Space wrap>
          <LeadAssigneeDropdownFilters filterMode={'select'} onSelectionChange={handleAssigneeSelectionChange} />
          <BranchDropdownFilters filterMode={'select'} onSelectionChange={handleLocationSelectionChange} />

          {/*<LeadMarketingChannelDropdownFilters*/}
          {/*  onSelectionChange={handleMarketingChannelSelectionChange}*/}
          {/*/>*/}

          <LeadSourceDropdownFilters onSelectionChange={handleLeadSourceSelectionChange} />
        </Space>
      </Card>
      <PerformanceChartToolbar
        selectedColumns={selectedColumns}
        handleColumnChange={handleColumnChange}
        tagRender={tagRender}
        xAxis={xAxis}
        setXAxis={setXAxis}
        handleExport={handleExport}
        columnOptions={metricOptions}
        xAxisOptions={groupByOptions}
      />

      <Card style={{ marginBottom: 16 }}>
        <Column loading={loading} {...config} />
      </Card>
      <PerformanceTable
        data={Array.isArray(leadsReportData) ? leadsReportData : []}
        loading={loading}
      />
    </MyPageContainer>
  );
};

export default Dashboard;
