import {SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {Badge, Button, Spin, Tag} from "antd";
import {useDispatch, useSelector} from "umi";
import {PlusOutlined} from "@ant-design/icons";
import styles from "./styles.less"
import classNames from "classnames";
import React, {useEffect, useRef, useState} from "react";
import LeadCard from "@/components/Kanban/LeadCard";
import {getKanbanColumnStyles} from "@/components/Kanban/utils";
import {F} from "@/utils/intl";
import debounce from "lodash/debounce";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors"

type ILeadColumnProps = {
  id: string,
  leadIds: string[],
  name: string,
  color: string,
  isSortingContainer: boolean
  dragOverlay?: any
}
const LeadColumn = (props: ILeadColumnProps) => {
  const {id, leadIds, name, color, isSortingContainer, dragOverlay, ...restProps} = props;
  const dispatch = useDispatch();
  const lastScrollTop = useRef(0);
  const [loading, setLoading] = useState(false)
  const shownLeadIds = leadIds
  const { isHeatMap } =
  useSelector((state: DefaultRootState) => ({
    isHeatMap: PreferencesSelectors.selectIsHeatMap(state),
  }));

  useEffect(() => {

    getScrollableContainer()?.addEventListener('scroll', onScroll)

    return () => {
      getScrollableContainer()?.removeEventListener('scroll', onScroll)

    }
  }, [props?.links])

  const {
    //active,
    attributes,
    isDragging,
    listeners,
    //over,
    setNodeRef,
    setActivatorNodeRef,
    transition,
    transform
  } = useSortable({
    id: id,
    data: {
      type: "SECTION"
    }
  });

  const triggerInfiniteScrollPagination = debounce(() => {
    if (restProps?.links?.next){
      setLoading(true)
      dispatch({
        type: 'leads/fetchLeadsByUrl',
        payload: {
          url: restProps?.links?.next,
          groupId: id,
          then: () => {
            setLoading(false)
          }
        }
      })
    }
  }, 500, {
    leading: true,
    trailing: false
  })

  const onScroll = () => {
    const scrollableContainer = getScrollableContainer()
    const scrollTop = scrollableContainer.scrollTop;
    const scrollHeight = scrollableContainer.scrollHeight;
    const clientHeight = scrollableContainer.clientHeight;
    // Check if the user is near the end (within 100px of the bottom)
    if (scrollTop > lastScrollTop.current) {
      if (scrollHeight - scrollTop <= clientHeight + 100) {
        triggerInfiniteScrollPagination()
      }
    }

    lastScrollTop.current = scrollTop;
  }


  const getScrollableContainerHtmlId = () => `kanban-column-${id}`
  const getScrollableContainer = () => document.getElementById(getScrollableContainerHtmlId())
  return (
    <div
      ref={setNodeRef}
      className={classNames(styles.kanbanColumn, 'kanban-column')}
      style={getKanbanColumnStyles({
        transform, dragOverlay, transition, isDragging, color: isHeatMap ? "white" : color
      })}
    >
      <div
        ref={setActivatorNodeRef}
        {...attributes}
        {...listeners}
        className={classNames(styles.kanbanColumnHeader, {
          [styles.draggingColumn]: dragOverlay
        })}
        style={{'--border-color': color}}
      >
        <Tag color={color}>{name}</Tag>
        <Badge
          count={restProps.total}
          showZero={true}
          overflowCount={Infinity}
          className={styles.columnBadge}
        />
      </div>
      <SortableContext
        items={shownLeadIds}
        strategy={verticalListSortingStrategy} // verticalListSortingStrategy rectSortingStrategy
      >
      <Spin spinning={loading} style={{height: "100%"}}>
        <div className={styles.kanbanColumnList} id={getScrollableContainerHtmlId()}>
          {shownLeadIds.map((item, _index) => {
            return (
              <LeadCard
                id={item}
                key={item}
                disabled={isSortingContainer}
              />
            );
          })}
        </div>
      </Spin>
      </SortableContext>
      <div className={styles.kanbanColumnFooter}>
        <Button
          type="text"
          icon={<PlusOutlined/>}
          size="small"
          style={{width: "100%", textAlign: "left"}}
          onClick={() => {
            dispatch({
              type: 'leads/toggleAddLeadsDrawer',
              payload: {
                statusId: id.split("column-")[1],
              },
            });

          }
          }
        >
          <F id={"pages.leads.addLead"} defaultMessage={"Add Lead"}/>
        </Button>
      </div>
    </div>
  );
};

export default LeadColumn
