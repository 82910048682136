import { useEffect } from 'react';
import {
  Button,
  Input,
  Form,
  Row,
  Col,
} from "antd";
import LeadStatusDropdownFilters from "@/components/Filters/LeadStatusFilters";
import { ProFormSelect } from "@ant-design/pro-form";
import { useSelector , FormattedMessage as F, useIntl } from 'umi';
import LeadAssigneeDropdownFilters from '@/components/Filters/LeadAssigneeDropdownFilters';
import * as LeadSelectors from "@/selectors/leadSelectors";
import LeadPriorityDropdownFilters from '@/components/Filters/LeadPriorityDropdownFilters';

const WebhookLeadSourceFromStep = ({
  isNew,
  onValuesChange,
  config,
  nextStep,
  onClose
}) => {
  const [form] = Form.useForm();
  const { userBranches } = useSelector(({ location: { userBranches } }) => ({
    userBranches,
  }));
  const { leadSourceDetail } = useSelector((state: DefaultRootState) => ({
    systemFields: LeadSelectors.selectLeadFields(state),
    leadName: state.journey?.selectedJourney?.name,
    leadSourceDetail: state.leads.leadSourceDetail?.lead_source,
  }));
  useEffect(() => {
    if (!isNew && leadSourceDetail) {
      form.setFieldsValue(leadSourceDetail);
    }
  }, [isNew, config, form]);
  const intl = useIntl();

  return (
    <>
      <Form form={form} layout="vertical" onValuesChange={onValuesChange} onFinish={nextStep}>
        <Row gutter={16} style={{ marginBottom: 20 }}>
          <Col span={12}>
            <Form.Item
              name="name"
              label={<F id={'pages.leadsources.name'} defaultMessage={'Name'} />}
              required
            >
              <Input placeholder="Enter the name of the source" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="incoming_lead_state_id" label="Entry State" required>
              <LeadStatusDropdownFilters filterMode={'select'} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginBottom: 20 }}>
          <Col span={12}>
            <Form.Item name="branch_id" label={<F id={'pages.leadsources.branch'} defaultMessage={'Branch'} />}>
              <ProFormSelect
                options={userBranches?.map((branch) => ({
                  value: branch.id,
                  label: branch.name,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="assignee_id" label={<F id={'pages.leadsources.assignedTo'} defaultMessage={"Assign To"} />}>
              <LeadAssigneeDropdownFilters filterMode={'select'} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: 20 }}>
          <Col span={12}>
            <Form.Item name="priority" label={<F id={'pages.leadsources.priority'} defaultMessage={"Priority"} />}>
              <LeadPriorityDropdownFilters filterMode={'select'} />
            </Form.Item>
          </Col>
          <Col span={12}></Col>
        </Row>
        <Form.Item>
          <Button type="default" onClick={onClose} style={{ margin: '0 8px' }}>
          <F id={'pages.common.cancel'} defaultMessage={"Cancel"} />
          </Button>
          <Button type="primary" htmlType="submit">
          <F id={'pages.common.save'} defaultMessage={"Save"} />
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default WebhookLeadSourceFromStep;
