import * as React from "react"

import {LeadModel} from "@/typings/models/Lead";
import {useDispatch} from "umi";
import BranchDropdownFilters from "@/components/Filters/BranchDropdownFilters";
import styles from "@/components/Lead/index.less";

const LeadBranch = ({lead, isTable}: {lead: LeadModel, isTable?: boolean}) => {
  const dispatch = useDispatch()
  const onChangeBranch = (newBranch) => {
    dispatch({
      type: 'leads/edit',
      payload: {
        branch_id: newBranch,
        leadId: lead.id
      }
    })
  }

  return <BranchDropdownFilters className={styles.branchContainer} selectProps={{bordered: false, allowClear: false}} filterMode={"select"} onChange={onChangeBranch} value={lead?.branch?.id}/>
}

export default LeadBranch
