import React, {useEffect, useState} from "react";
import {Badge, Popover, Space} from "antd";
import styles from "./index.less"
import classNames from "classnames";

export const LEAD_STATUS_COLORS = [
  "#FF6F61",  // Coral
  "#6B5B95",  // Deep Purple
  "#88B04B",  // Soft Green
  "#F7CAC9",  // Light Pink
  "#92A8D1",  // Cool Blue
  "#955251",  // Mauve
  "#B565A7",  // Plum
  "#009B77",  // Dark Teal
  "#DD4124",  // Red Orange
  "#45B8AC",  // Mint
  "#D65076",  // Orchid
  "#EFC050",  // Warm Yellow
  "#5B5EA6",  // Indigo
  "#9B2335",  // Burgundy
  "#BC243C",  // Crimson
  "#55B4B0",  // Turquoise
  "#E15D44",  // Burnt Orange
  "#7FCDCD",  // Aquamarine
  "#D94F70",  // Raspberry
  "#6C4F3D"   // Coffee Brown
];

export const LeadStatusColor = ({color, isCurrent = false, onClick}: {
  color: string,
  isCurrent?: boolean,
  onClick?: () => {}
}) => {
  return <div onClick={onClick}>
    <Badge className={classNames(styles.statusColorBadge, {
      [styles.activeBadge]: isCurrent
    })} color={color} style={{borderColor: color}}/>
  </div>
}

export const LeadStatusColorPopover = ({color, onSelect, defaultOpen, onOpenChange}) => {
  const [open, setOpen] = useState<boolean>(defaultOpen)

  useEffect(() => {
    handleOpenChange(defaultOpen)
  }, [defaultOpen]);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
    onOpenChange(newOpen)
  }
  return <div>
    <Popover trigger={["click"]} defaultOpen={defaultOpen} open={open} onOpenChange={handleOpenChange} content={<div className={styles.leadStatusColorPickerInnerContainer}>
      <Space wrap>
        {LEAD_STATUS_COLORS.map(newColor => <LeadStatusColor key={newColor} color={newColor}
                                                             onClick={() => onSelect(newColor)}
                                                             isCurrent={newColor === color}/>)}
      </Space>
    </div>}>
      <div className={styles.leadColorPopoverHandle}>
        <LeadStatusColor color={color}/>
      </div>
    </Popover>
  </div>
}


