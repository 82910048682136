
export const ROUTE_PATHS: { [key: string]: string } = {
    CHECK: '/check',
    MIMIC: '/mimic',
    USER: '/user',
    USER_LOGIN: '/user/login',
    USER_FORGOT_PASSWORD: '/user/forgotpassword',
    RESET_PASSWORD: '/set-password/:token',
    REPORTS: '/reports/',
    REPORTS_DASHBOARD: '/reports/dashboard',
    REPORTS_MARKET_RESEARCH: '/reports/market-research',
    REPORTS_PERFORMANCE: '/reports/performance',
    REPORTS_CTA: '/reports/cta',
    CUSTOMERS: '/customers',
    SUPPORT: '/support',
    SERVICE_REQUESTS: '/service-requests',
    LOCATIONS: '/locations',
    //STUDIO: '/studio',
    IMAGES: '/images',
    TAG_STATISTICS: '/tag-statistics',
    PROMOTIONS: '/promotions',
    SETTINGS: '/settings',
    COMPONENTS: '/componnents',
    PROFILE: '/profile',
    LANGUAGE: '/language',
    BILLINGS: '/billings/',
    BILLINGS_DETAILS: '/billings/:billingId',
    ROOT: '/',
    LOGIN: '/user/login',
    LEADS: '/leads',
    USER_MANAGEMENT: '/usermanagement',
    REPORTS_LEAD_MANAGEMENT: '/lead-management-reports/',
    REPORTS_DASHBOARD_LEAD_MANAGEMENT: '/lead-management-reports/dashboard',
    REPORTS_FUNNEL_LEAD_MANAGEMENT: '/lead-management-reports/funnel',
    TASKS: '/tasks',
    EMAIL_AUTOMATION: '/email-automation',
  };

