import get from 'lodash/get';

import {
  getDisplayNamesForPath,
  getRolesForComponent,
  getRolesForPath,
  ROOMTAILOR,
  SUPER_ADMIN
} from '../typings/roleMapping';

import storage from 'conversional-persistent-storage';
import {UserModel} from "@/typings/models/User";
import dev from "@/constants/environment/dev";
import {useModel} from "umi";

const getUserDevRole = () => {
  try {
    if (storage.getItem('rootApiUrl',undefined,"localStorage") === dev.ROOT_URL) {
      const devRole = storage.getItem('devModeRole',undefined,"localStorage");
      if (devRole && devRole !== 'null') {
        const userDevRole = JSON.parse(devRole);
        return userDevRole?.["role"] ?? SUPER_ADMIN;
      }
      return SUPER_ADMIN;
    }
    return null;
  } catch (error) {
    console.error('Error accessing role:', error);
    return null;
  }
}
const isConversionalAdminRoles = (role: string) => role === SUPER_ADMIN || role === ROOMTAILOR
export const getUserRole: (user: UserModel) => string = (user) => {
  return getUserDevRole() ?? get(user, 'role.role', get(user, 'User.role.role', ""));
}

export function checkUserAccessToPath(user: UserModel, path: string): boolean {
  const userRole = getUserRole(user)
  if(!userRole) return false;
  if (isConversionalAdminRoles(userRole)) return true;
  const pathRoles = getRolesForPath(path)
  return pathRoles.includes('*') || pathRoles.includes(userRole);
}
export function checkUserAccessToComponent(user: UserModel, component: string): boolean {
  const userRole = getUserRole(user)
  if(!userRole) return false;
  if (isConversionalAdminRoles(userRole)) return true;
  const componentRoles = getRolesForComponent(component)

  return componentRoles.includes('*') || componentRoles.includes(userRole);
}
export const getRoleCustomSidebarMenuDisplayName = (user: UserModel, path: string) => {
  const userRole = getUserRole(user)
  if(!userRole) return null;
  if (isConversionalAdminRoles(userRole)) return null;
  return getDisplayNamesForPath(path, userRole)
}

export const hasAccess = (user, access) => {
  if (!access) return true
  const userRole = getUserRole(user)
  if (!Array.isArray(access))
    access = [access]
  if (access.includes(userRole)) return true
  return false
}
export const CheckAccess = ({access, children, fallback = null}) => {
  const {initialState} = useModel('@@initialState')
  const userRole = getUserRole(initialState.currentUser?.User)
  if (!Array.isArray(access))
    access = [access]
  if (access.includes(userRole)) return children
  return fallback || null
}
