import React, { useEffect } from 'react';
import {
  CloudUploadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Spin,
  Upload,
  Input,
  Form,
  Row,
  Col,
  Steps,
  Typography,
  Divider,
  Space
} from "antd";
import LeadStatusDropdownFilters from "@/components/Filters/LeadStatusFilters";
import { ProFormSelect } from "@ant-design/pro-form";
import { useSelector } from 'umi';
import LeadAssigneeDropdownFilters from '@/components/Filters/LeadAssigneeDropdownFilters';
import LeadPriorityDropdownFilters from '@/components/Filters/LeadPriorityDropdownFilters';
import * as LeadSelectors from '@/selectors/leadSelectors';

const { Text } = Typography;
const { Step } = Steps;

const CSVLeadSourceImportSegment = ({
  leadName,
  isNew,
  handleFileUpload,
  loadingState,
  isUploading,
  onValuesChange,
  config,
}) => {
  const [form] = Form.useForm();
  const { userBranches } = useSelector(({ location: { userBranches } }) => ({
    userBranches,
  }));

  useEffect(() => {
    if (!isNew && config) {
      form.setFieldsValue(config);
    }
  }, [isNew, config, form]);
  

  return (
    <Form form={form}  layout="vertical" onValuesChange={onValuesChange}>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <Form.Item  name="name" label="Name" required>
            <Input placeholder="Enter the name of the source" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="incoming_lead_state_id"
            label="Entry State"
            required
          >
            <LeadStatusDropdownFilters filterMode={'select'} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <Form.Item name="branch_id" label="Branch">
            <ProFormSelect
              options={userBranches?.map((branch) => ({
                value: branch.id,
                label: branch.name,
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="assignee_id" label="Assign To">
            <LeadAssigneeDropdownFilters filterMode={'select'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <Form.Item name="priority" label="Priority">
            <LeadPriorityDropdownFilters filterMode={'select'} />
          </Form.Item>
        </Col>
        <Col span={12}></Col>
      </Row>

      {/* Upload Box */}
      <Row justify="center" style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Form.Item>
            <Upload.Dragger
              accept=".csv"
              beforeUpload={handleFileUpload}
              showUploadList={false}
              style={{ padding: '24px', borderRadius: '8px' }}
            >
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined style={{ fontSize: 48, color: '#5CC6BA' }} />
              </p>
              <p className="ant-upload-text">Browse or drop your file (CSV) here</p>
            </Upload.Dragger>
          </Form.Item>
        </Col>
      </Row>

      {/* Loading Spinner */}
      {isUploading && (
        <Row justify="center" style={{ marginTop: 20 }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          <p style={{ marginTop: 10 }}>{loadingState}</p>
        </Row>
      )}
    </Form>
  );
};

export default CSVLeadSourceImportSegment;
