import React, {useEffect} from 'react';
import {useDispatch, useIntl, useSelector} from 'umi';
import {Badge, Button, Divider, Form, Space, Tabs} from 'antd';

import Drawer from '@/components/Drawer';
import * as LeadSelectors from "@/selectors/leadSelectors";
import isEmpty from "lodash/isEmpty";
import styles from './index.less';
import LeadName from "@/components/Lead/LeadName";
import {LeadCardOrderedProperties} from "@/components/Lead/LeadProperties";
import {InboxOutlined, MailOutlined, PhoneOutlined} from "@ant-design/icons";
import LeadStatus from "@/components/Lead/LeadStatus";
import get from "lodash/get";
import LeadContextMenu from "@/components/Lead/LeadContextMenu";
import LeadActivity from "@/components/Lead/LeadActivity";
import {TasksTable} from "@/pages/Tasks/TasksTable";
import {F} from "@/utils/intl";

const LeadDetailsDrawer = () => {
  const [form] = Form.useForm()
  const detailsDrawer = useSelector(LeadSelectors.selectLeadDetailsDrawer)
  const lead = detailsDrawer?.lead
  const leadDetails = useSelector((state: DefaultRootState) => LeadSelectors.selectLeadDetails(state, lead?.id))

  const fetchLeadData = () => dispatch({
    type: 'leads/fetchSingleLead',
    payload: {
      leadId: lead?.id
    }
  })

  const tabs = [
    {
      key: '1',
      label: <F id={"pages.lead.drawer.details"} defaultMessage={'Details'}/>,
      children: <div>
        <LeadStatus lead={lead}/>
        <LeadCardOrderedProperties lead={lead} showAllProperties spaceSize={24}/>
      </div>,
    },
    {
      key: '2',
      label: <F id={"pages.lead.drawer.activities"} defaultMessage={'Activities'}/>,
      children: <div>
        <LeadActivity leadDetails={leadDetails}/>
      </div>,
    },
    {
      key: '3',
      label: <Space>
        <F id={"pages.lead.tasks.title"} defaultMessage={'Tasks'}/>
        <Badge showZero={false} count={lead?.tasks_count}/>
      </Space>,
      children: <div>
        <TasksTable leadId={lead?.id} filterColumns={column => column.dataIndex !== 'lead'} data={leadDetails?.tasks || []} request={fetchLeadData}/>
      </div>,
    },
    {
      key: '4',
      children: <div>
        List all Conversional service requests + results + chosen styles
      </div>,
    }
  ];

  const dispatch = useDispatch();

  function toggle() {
    dispatch({
      type: 'leads/toggleLeadDetailsDrawer',
    });
  }

  useEffect(() => {
    if (isEmpty(detailsDrawer))
      return

    form.resetFields()
    fetchLeadData()
  }, [detailsDrawer]);

  const intl = useIntl();

  const getTitle = () => lead && <LeadName hideAvatar lead={lead}/>

  if (!lead) return null

  return (
    <div className={styles.drawerContainer}>
      <Drawer
        width={500}
        title={getTitle()}
        open={!isEmpty(detailsDrawer)}
        destroyOnClose
        className={styles.drawer}
        onVisibleChange={toggle}
        drawerHeaderClassName={styles.drawerHeader}
      >
        <div className={styles.actions}>
          <Space>
            {get(lead, 'customer.email') &&
              <a href={`mailto:${get(lead, 'customer.email')}`}><Button shape={"circle"} size={"large"}
                                                                        icon={<MailOutlined/>}/></a>}
            {get(lead, 'customer.phone') &&
              <a href={`tel:${get(lead, 'customer.phone')}`}><Button shape={"circle"} size={"large"}
                                                                     icon={<PhoneOutlined/>}/></a>}
            <LeadContextMenu lead={lead} actionsFilter={['archive']} archiveContent={<Button shape={"circle"} size={"large"} icon={<InboxOutlined/>}/>}/>
          </Space>
        </div>
        <Divider/>
        <Tabs defaultActiveKey="1" items={tabs} />
      </Drawer>
    </div>
  );
};

export default LeadDetailsDrawer;
