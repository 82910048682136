import React, {useEffect, useState} from "react";
import {closestCenter, DndContext, DragOverlay} from "@dnd-kit/core";
import {arrayMove, SortableContext, verticalListSortingStrategy,} from "@dnd-kit/sortable";
import styles from "./index.less"
import * as LeadSelectors from "@/selectors/leadSelectors"
import {useDispatch, useSelector} from "umi";
import EditableStatusCard, {LEAD_STATE_TYPES} from "@/components/Kanban/StatusManager/EditableStatusCard";
import {LeadStatusModel} from "@/typings/models/LeadStatus";
import {createLeadStatusRequest, deleteLeadStatusRequest} from "@/services/api/lead";
import get from "lodash/get";
import {message, Spin} from "antd";
import isEmpty from "lodash/isEmpty";

const LeadStatusManager = () => {

  const INITIAL_NEW_STATUS_FORM = {
    color: "#92A8D1",
    label: '',
    state_type: LEAD_STATE_TYPES.OPEN
  }

  const [activeId, setActiveId] = useState(null);
  const [statuses, setStatuses] = useState<LeadStatusModel[]>([]);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [newStatusForm, setNewStatusForm] = useState(INITIAL_NEW_STATUS_FORM);

  const statusSettings = useSelector(LeadSelectors.selectStatusSettings)
  const dispatch = useDispatch()
  useEffect(() => {
    setStatuses(statusSettings)
  }, [statusSettings]);

  const reload = () => {
    dispatch({
      type: 'leads/fetchLeadStatuses'
    })
    dispatch({
      type: 'leads/fetch'
    })
  }
  useEffect(() => {
    if (!initialized || statuses?.length === 0) return

    setInitialized(true)

    dispatch({
      type: "lead/saveLeadStatuses",
      payload: statuses
    })
  }, [statuses]);

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const {active, over} = event;
    setActiveId(null);

    const fromIndex = findStatusIndex(active.id)
    const toIndex = findStatusIndex(over.id)
    setStatuses((prev) => {
      const newOrderedStatus = arrayMove(
        [...prev],
        fromIndex,
        toIndex
      )
      dispatch({
        type: "leads/changeLeadStatusOrder",
        payload: {
          order: newOrderedStatus.map(status => status.id)
        }
      })
      return newOrderedStatus
    });
  };

  const handleEdit = (id, newLabel) => {
    setStatuses(prev => prev.map(card => {
      if (card.id === id) return sendEditRequest(id, {...card, label: newLabel})
      return card
    }));
  };

  const handleColorPopoverOpenChange = () => {

  }
  const handleCreate = (_, label) => {
    setNewStatusForm(INITIAL_NEW_STATUS_FORM)
    if (isEmpty(label)) return
    const formData = {
      ...newStatusForm,
      label
    }
    setLoading(true)
    createLeadStatusRequest({data: formData}).then(({response, data}) => {
      setLoading(false)
      setStatuses((prev) => prev.concat(data?.data))
      dispatch({
        type: 'leads/fetchLeadStatuses'
      })
      dispatch({
        type: 'leads/fetch'
      })
      reload()
    })
  };

  const handleDelete = (id) => {

    return new Promise((resolve, reject) => {
      deleteLeadStatusRequest({
        params: {
          statusId: id
        }
      }).then(({response, data}) => {
        resolve(null)
        if (response?.status !== 200)
          return message.error(get(data, 'data.message', data?.message))

        setStatuses(prev => prev.filter(status => status.id !== id))
        reload()
      })
    })
  };

  const handleColorSelect = (id, newColor) => {
    setStatuses(prev => prev.map(card => {
      if (card.id === id) return sendEditRequest(id, {...card, color: newColor})
      return card
    }))
  }


  const handleAutomationEventChange = (id, automationEvent) => {
    setStatuses(prev => prev.map(card => {
      // -1 is for manual
      if (automationEvent === "-1") {
        return card.id === id ? {...card, automationEvent} : card
      }
      return card.id === id ? {...card, automationEvent} : ((card.automationEvent === automationEvent) ? {
        ...card,
        automationEvent: "-1"
      } : card)
    }))
  }

  const sendEditRequest = (id, state) => {
    const payload = {...state}
    if (typeof payload.state_type === "object"){
      payload.state_type = payload.state_type.value
    }
    dispatch({
      type: 'leads/editLeadState',
      payload: {
        statusId: id,
        ...payload,
      }
    })
    return state
  }
  const handleLeadStateTypeChange = (id, newStateType) => {
    setStatuses(prev => prev.map(card => {
      if (card.id === id) return sendEditRequest(id, {...card, state_type: newStateType})
      return card
    }))
  }

  const findStatusIndex = (cardId) => {
    return statuses.findIndex((item) => item.id === cardId);
  };

  const findCardById = (id) => {
    return statuses.find(status => status.id === id)
  };

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      collisionDetection={closestCenter}
    >
      <SortableContext
        items={statuses}
        strategy={verticalListSortingStrategy}
      >
        <Spin spinning={loading}>
          <div className={styles.statusCategoryContainer}>
            {statuses.map(status => <EditableStatusCard
              {...status}
              key={status.id}
              onEdit={handleEdit}
              draggable={statuses.length > 1}
              onColorSelect={handleColorSelect}
              onDelete={handleDelete}
              onAutomationEventChange={handleAutomationEventChange}
              onLeadStateTypeChange={handleLeadStateTypeChange}
            />)}

            <EditableStatusCard isNew
                                onColorSelect={(id, color) => {
                                  setNewStatusForm({
                                    ...newStatusForm,
                                    color
                                  })
                                }}
                                onEdit={handleCreate}
                                {...newStatusForm}

            />
          </div>
        </Spin>

      </SortableContext>

      <DragOverlay>
        {activeId ? (
          <EditableStatusCard
            {...findCardById(activeId)}
            dragPlaceholder
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default LeadStatusManager;
