import styles from './index.less';
import React, { useEffect } from 'react';
import { useDispatch, useSelector, FormattedMessage as F } from 'umi';
import Drawer from '@/components/Drawer';
import LeadSourceSwitcher from './LeadSourceSwitcher';

const AddLeadDrawer: React.FC = () => {
  
  const { visible } = useSelector(({ leads: { addLead } }: DefaultRootState) => ({
    visible: addLead?.drawer?.visible,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'location/getUserBranches',
    });
  }, []);
  function toggle() {
    dispatch({
      type: 'leads/toggleAddLeadsDrawer',
    });
  }
  return (
    <div className={styles.drawerContainer}>
      <Drawer
        title={""}
        visible={visible}
        onVisibleChange={toggle}
        width={"70%"}
      >
        <LeadSourceSwitcher/>
      </Drawer>
    </div>
  );
};

export default AddLeadDrawer;