import * as React from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'umi';
import {Badge, Space} from 'antd';

import LeadDropdownFilter from '@/components/Filters/LeadDropdownFilter';
import {F} from '@/utils/intl';
import * as LeadSelectors from '@/selectors/leadSelectors';
import {LeadStatusModel} from '@/typings/models/LeadStatus';


export const LeadStatusPresentation = ({status}) => <Space>
  <Badge color={status.color}/>
  <span>{status.label}</span>
</Space>

const LeadStatusDropdownFilter = (props) => {
  const dispatch = useDispatch()
  const leadStatuses = useSelector(LeadSelectors.selectStatusSettings)
  const optionRender = (status:LeadStatusModel) => <LeadStatusPresentation status={status}/>

  useEffect(() => {
    if (leadStatuses.length === 0){
      dispatch({
        type: 'leads/fetchLeadStatuses'
      })
    }
  }, []);

  return <LeadDropdownFilter
    optionRender={optionRender}
    placeholder={<F id={"pages.leads.filters.status.placeholder"} defaultMessage={'Status'}/>}
    filterKey={'lead_state'}
    options={leadStatuses}
    {...props}/>
}

export default LeadStatusDropdownFilter
