import React from "react";

import {LeadModel} from "@/typings/models/Lead";
import EditableTextField from "@/components/Lead/EditableTextField";

const LeadPhone = ({lead}: {lead: LeadModel}) => {
  const {customer} = lead
  return <EditableTextField lead={lead} property={'customer.phone'}>
    <a href={customer.phone && `tel:${customer.phone}`}>{customer.phone || " - "}</a>
  </EditableTextField>
}

export default LeadPhone
