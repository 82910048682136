import * as React from 'react'
import {connect, FormattedMessage as F} from 'umi'
import {PageContainer, PageContainerProps} from '@ant-design/pro-layout';
import MyRangePicker from "@/components/MyRangePicker";
import {DownloadOutlined} from '@ant-design/icons';
import {Button, Grid} from 'antd';
import ClickTracker from "@/components/ClickTracker";
import {exportEventBuilder} from "@/services/tracker/events";
import {DispatchFunction} from "@/typings/models/ReduxState";
import UserMenuDropdown from "@/components/UserMenuDropdown";


interface MyPageContainerExporTableProps {
  url?: string | boolean,
  downloadAction?: string | Function
}
interface MyPageContainerExtraProps {
  hasRangePicker?: boolean,
  exportTable: MyPageContainerExporTableProps
  actions?: React.ReactNode[]
}

interface MyPageContainerProps extends PageContainerProps {
  children: any,
  label: any,
  extraProps?: MyPageContainerExtraProps,
  dispatch: DispatchFunction
}

const REDUX_ACTION = "customers/downloadExport"

const MyPageContainer: React.FC<MyPageContainerProps> = ({children, label, dispatch, extraProps = {}, ...props}: MyPageContainerProps) => {
  const {useBreakpoint} = Grid;
  const screens = useBreakpoint()

  const downloadExport = () => {
    if(extraProps && extraProps.exportTable && extraProps.exportTable.downloadAction){
      if (typeof extraProps.exportTable.downloadAction === "string"){
        dispatch({
          type: extraProps.exportTable.downloadAction
        })
      } else if (typeof extraProps.exportTable.downloadAction === "function"){
        extraProps.exportTable.downloadAction()
      }
    }
  }

  function getButton(){
    const shape = screens.md ? "round" : "circle"
    return <ClickTracker event={exportEventBuilder({
      label
    })}>
      <Button onClick={downloadExport} size={"large"} shape={shape} type={"primary"} key="out" icon={<DownloadOutlined/>}>
        {screens.md && <F defaultMessage={"Download CSV"} id={"component.table.downloadCSV"}/>}
      </Button>
    </ClickTracker>
  }


  function getExtra(){
    const extra = extraProps.actions || []

    if (extraProps?.hasRangePicker){
      extra.push(<MyRangePicker bordered={true}/>)
    }
    if (extraProps?.exportTable !== undefined) {
      if (extraProps?.exportTable.url) {
        extra.push(getButton())
      }
    }
    return [
      ...extra,
      <UserMenuDropdown/>
    ]
  }

  return <PageContainer {...props} extra={getExtra()}>
    {children}
  </PageContainer>
}

export default connect()(MyPageContainer)
