import * as React from "react";
import {useEffect, useState} from "react";
import {Select, SelectProps} from "antd";
import {useDispatch, useSelector} from "umi";

import * as LeadSelectors from "@/selectors/leadSelectors"
import styles from "./index.less"
import classNames from "classnames";

const {Option} = Select;

type ILeadDropdownOption = {
  name?: string,
  label?: string,
  title?: string,
  value?: string,
  id?: string | number,
  color?: string,
  hashId?: string,
  [otherKey: string]: any
}
type ILeadDropdownFilterProps = {
  options: ILeadDropdownOption[],
  filterKey: string,
  fetchOptionsAction?: string,
  optionLabelKey?: string,
  optionValueKey?: string,
  placeholder?: any,
  filterMode?: string | null,
  localSearch?: boolean,
  autoFocus?: boolean,
  value?: any,
  optionRender?: (option: ILeadDropdownOption) => React.ReactNode,
  onChange?: (value, option) => void
  onSearch?: (query) => void
}

const FILTER_PLACES = {
  LIST: 'LEAD_LIST',
  REPORTS: 'LEAD_REPORTS'
}
const FILTER_ACTIONS = {
  [FILTER_PLACES.LIST]: 'leads/changeFilter'
}
/**
 * This is the generic component for lead table/kanban dropdown filters.
 * Using this component, only by passing the options and the key of the filter, a Select component
 * gets rendered. And by selecting one of the options, the right action for storing the filter and
 * then, the request for updating the leads table is made.
 *
 * The filters are stored in Redux and rehydrated. So they don't reset on each page reload.
 * Currently, the state is only in Redux store. But it's easy to set it in the DB and pass to the backend.
 * */
const LeadDropdownFilter = ({
                              options,
                              className,
                              filterKey,
                              onChange,
                              fetchOptionsAction,
                              optionLabelKey,
                              optionValueKey,
                              localSearch = true,
                              filterMode = FILTER_PLACES.LIST,
                              onSearch,
                              value,
                              autoFocus,
                              optionRender,
                              placeholder,
                              ...restProps
                            }: ILeadDropdownFilterProps) => {
  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()

  const filters = useSelector(LeadSelectors.selectLeadFilters)

  const getValue = () => {
    if (filterMode === FILTER_PLACES.LIST)
      return filters[filterKey]
    return value
  }
  useEffect(() => {
    /**
     *  If there's the need for fetching the options, you can pass the `fetchOptionsAction` to the component.
     * */
    if (fetchOptionsAction) dispatch({
      type: fetchOptionsAction
    })

    if (autoFocus) setOpen(true)
  }, [])
  const handleChange = (value, option) => {
    // Resetting the value on clear needs explicitly setting `null` value
    value = value === undefined ? null : value
    const reduxAction = FILTER_ACTIONS[filterMode]
    if (reduxAction)
      dispatch({
        type: reduxAction,
        payload: {
          [filterKey]: value
        }
      })

    onChange && onChange(value, option)
  }

  const handleSearch = (query) => {
    /**
     * Local search is enabled by default. If there's onSearch function passed, it will be called with the query.
     * Ideally, there's an invocation of the fetch action for fetching the options from the server.
     * */
    if (onSearch) onSearch(query)
  }

  // Since data types may vary among different filters, this function sets the right `value` for each option
  const getOptionValue = option => option[optionValueKey] || option.value || option.hashId || option.id

  // Since data types may vary among different filters, this function sets the right `label` for each option
  const getOptionLabel = option => option[optionLabelKey] || option.name || option.label || option.title

  const getOptions = () => {
    const safeOptions = Array.isArray(options) ? options : [];
    return safeOptions.map(option => ({
      ...option,
      label: getOptionLabel(option),
      value: getOptionValue(option)
    }));
  };

  const props: SelectProps = {}
  let children = null
  // If we need to customize how we show the options, e.g: Adding the assignee avatar, we can pass `optionRender` function and return the proper JSX
  if (optionRender) children = getOptions().map(option => <Option value={getOptionValue(option)}
                                                                  key={getOptionValue(option)}
                                                                  label={getOptionLabel(option)}>
    {optionRender(option)}
  </Option>)
  else props.options = getOptions()

  if (autoFocus){
    props.autoFocus = autoFocus
    props.open = open
    props.onDropdownVisibleChange = setOpen
  }


  return <Select
    showSearch
    placeholder={placeholder}
    allowClear
    optionFilterProp="label"
    value={getValue()}
    onChange={handleChange}
    onSearch={handleSearch}
    className={classNames(styles.selectContainer, className)}
    {...(restProps.selectProps || {})}
    {...props}
  >
    {children}
  </Select>
}

export default LeadDropdownFilter
