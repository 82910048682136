import React, {useState} from 'react';
import {List} from 'antd';
import classNames from "classnames";
import styles from "./index.less"
import Property from "@/components/Kanban/VisibilitySettings/Property";
import {ORDERED_LEAD_PROPERTIES} from "@/components/Kanban/VisibilitySettings/PropertyVisibilitySettings";

export default function FieldList({fields, onToggle, isVisible}) {
  const [animationClasses, setAnimationClasses] = useState<{ [field: string]: string }>({});

  const handleToggle = (field, nextIsVisible) => {
    setAnimationClasses((prev) => ({
      ...prev,
      [field]: classNames(styles.fadeOut, {
        [styles.fadeOutForHiddenItems]: nextIsVisible
      }),
    }));

    // Wait for the animation to finish before updating visibility
    setTimeout(() => {
      onToggle(field, nextIsVisible);
      setTimeout(() => {
        setAnimationClasses(prev => ({
          ...prev,
          [field]: ''
        }))
      }, 100)
    }, 300); // Match the CSS transition time (0.3s)
  };

  return (
    <List
      bordered
      className={styles.listContainer}
      size={"small"}
      dataSource={fields}
      renderItem={(field) => (
        <List.Item onClick={() => handleToggle(field, !isVisible)}>
          <Property hasSort={ORDERED_LEAD_PROPERTIES.includes(field)} field={field} className={animationClasses[field]} isVisible={isVisible}/>
        </List.Item>
      )}
    />
  );
}
