import {processAPIResponse} from "@/utils/request";
import { createEmailAutomationRequest, deleteEmailAutomationRequest, fetchEmailAutomationListRequest, fetchEmailAutomationVariableListRequest, updateEmailAutomationRequest } from "@/services/api/automation";
import { message } from "antd";

const processReceivers = (receivers) => {
  const payload = {};

  const leadManagersIndex = receivers.findIndex(receiver => receiver === 'send_to_lead_managers');
  if (leadManagersIndex !== -1) {
    payload['send_to_lead_managers'] = true;
    receivers.splice(leadManagersIndex, 1);
  }

  const assigneeIndex = receivers.findIndex(receiver => receiver === 'send_to_assignee');
  if (assigneeIndex !== -1) {
    payload['send_to_assignee'] = true;
    receivers.splice(assigneeIndex, 1); 
  }
  return payload;
};
export default {
  namespace: 'automation',

  state: {
    emailAutomationList: {
        data: [],
        links:{},
          total: 0
    },
    emailAutomationVariableList: {}
  },

  effects: {
  
    * fetchEmailAutomationList({ }, { call, put, select }) {
          const { response, data } = yield call(fetchEmailAutomationListRequest, { query: {} });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'setEmailAutomationList',
            payload: data
          })
        },
        * onError(error) {

        }
      })
    },
    * createEmailAutomation({payload}, {call, put, select}) {
    payload['send_to_lead_managers'] = false
    payload['send_to_assignee'] = false
      payload = { ...payload, ...processReceivers(payload.receivers) }
    const {response, data} = yield call(createEmailAutomationRequest, {payload});

    yield processAPIResponse({response, data}, {
      * onSuccess(data) {
        yield put({
          type: 'fetchEmailAutomationList',
        })
        payload.intl && message.success(payload.intl.formatMessage({
          id: "pages.automations.createEmailAutomationSuccess",
          defaultMessage: 'Email Automation created successfully'
        }))
      }
      , * onError(error) {
        message.error(error?.message || payload.intl.formatMessage({
          id: "pages.automations.createEmailAutomationFailed",
          defaultMessage: 'Creating Email Automation failed'
        }))
      }
    })
    },
    * deleteEmailAutomation({payload: {id, cb, intl}}, {call, put}) {
    const {response, data} = yield call(deleteEmailAutomationRequest, {params: {id}});
    yield processAPIResponse({response, data}, {
      * onSuccess() {
        yield put({
          type: "fetchEmailAutomationList"
        })
        intl && message.success(intl.formatMessage({ id: "pages.automations.removeEmailAutomationSuccess", defaultMessage: 'Email Automation deleted successfully' }))
        cb && cb(data)
      },
      * onError(error) {
        message.error(error?.message || intl.formatMessage({ id: "pages.automations.removeEmailAutomationFailed", defaultMessage: 'Remove Email Automation failed' }))
      },
    })
    },
    * fetchEmailAutomationVariableList({ }, { call, put, select }) {
      const { response, data } = yield call(fetchEmailAutomationVariableListRequest, { query: {} });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'setEmailAutomationVariableList',
            payload: data
          })
        },
        * onError(error) {

        }
      })
    },
    * editEmailAutomation({ payload: { cb, intl, id, ...body } }, { call, put, select }) {
    body['send_to_lead_managers'] = false
      body['send_to_assignee'] = false
      body = { ...body, ...processReceivers(body.receivers) }

      const { response, data } = yield call(updateEmailAutomationRequest, {
        params: { id },
        payload: body
      }
      );
      yield processAPIResponse({ response, data }, {
        
        * onSuccess(data) {
        yield put({
          type: "fetchEmailAutomationList"
        })
            cb&& cb(data);
        },
        * onError(error) {
          cb && cb({ message: error?.message || intl.formatMessage({ id: "pages.automations.editEmailAutomationFailed", defaultMessage: 'Edit Email Automation failed' }) });
          message.error(error?.message || intl.formatMessage({ id: "pages.automations.removeEmailAutomationFailed", defaultMessage: 'Remove Email Automation failed' }))
        },
      })
    },

  },
  reducers: {
    setEmailAutomationList(state, action) {
      return {
        ...state,
        emailAutomationList: action.payload
      }
      },
      setEmailAutomationVariableList(state, action) {
      return {
        ...state,
        emailAutomationVariableList: action.payload.data
      }
      }
  }
};
