import React, {useEffect, useState} from 'react';
import {Divider} from 'antd';
import {useDispatch, useSelector} from "umi";
import {closestCenter, DndContext, DragOverlay} from '@dnd-kit/core';
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';

import * as PreferencesSelectors from "@/selectors/preferencesSelectors"

import SearchBar from './SearchBar';
import FieldList from './FieldList';
import {F} from "@/utils/intl";
import Property from "@/components/Kanban/VisibilitySettings/Property";

export const LEAD_PROPERTIES = {
  NAME: 'name',
  EMAIL: 'email',
  ASSIGNEE: 'assignee',
  COMMENTS: 'comments',
  PHONE: 'phone',
  PRIORITY: 'priority',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  APPOINTMENT_AT: 'appointmentAt',
  DEAL_SIZE: 'dealSize',
  LEAD_SOURCE: 'leadSource',
  MARKETING_CHANNEL: 'marketingChannel',
  STATUS: 'status',
  BRANCH: 'branch',
  SOURCE: 'leadSource',
  NOTE: 'note',
}
export const ORDERED_LEAD_PROPERTIES = [LEAD_PROPERTIES.NAME, LEAD_PROPERTIES.EMAIL, LEAD_PROPERTIES.STATUS,  LEAD_PROPERTIES.BRANCH, LEAD_PROPERTIES.PHONE, LEAD_PROPERTIES.CREATED_AT,
  LEAD_PROPERTIES.UPDATED_AT, LEAD_PROPERTIES.DEAL_SIZE, LEAD_PROPERTIES.PRIORITY, LEAD_PROPERTIES.MARKETING_CHANNEL, LEAD_PROPERTIES.NOTE, LEAD_PROPERTIES.ASSIGNEE, LEAD_PROPERTIES.ASSIGNEE]
// TBD later by business
const INITIAL_PROPERTY_VISIBILITY_SETTINGS = {
  shown: [LEAD_PROPERTIES.NAME, LEAD_PROPERTIES.STATUS, LEAD_PROPERTIES.PRIORITY, LEAD_PROPERTIES.EMAIL, LEAD_PROPERTIES.PHONE, LEAD_PROPERTIES.ASSIGNEE, LEAD_PROPERTIES.BRANCH],
  hidden: [LEAD_PROPERTIES.CREATED_AT, LEAD_PROPERTIES.UPDATED_AT, LEAD_PROPERTIES.DEAL_SIZE, LEAD_PROPERTIES.MARKETING_CHANNEL, LEAD_PROPERTIES.SOURCE, LEAD_PROPERTIES.NOTE]
}
export default function PropertyVisibilitySettings({hide = false}) {
  const initialFields = useSelector(PreferencesSelectors.selectLeadsVisibilitySettings) || INITIAL_PROPERTY_VISIBILITY_SETTINGS
  const isPreferencesInitialized = useSelector(PreferencesSelectors.selectIsPreferencesInitialized)
  const [fields, setFields] = useState(initialFields);

  const [search, setSearch] = useState('');
  const [activeItem, setActiveItem] = useState(null); // Track the active item being dragged

  const dispatch = useDispatch()
  const handleFieldToggle = (field, isVisible) => {
    setFields((prev) => {
      if (isVisible) {
        const orderedFields = prev.shown.filter(field => ORDERED_LEAD_PROPERTIES.includes(field))
        const notOrderedFields = prev.shown.filter(field => !ORDERED_LEAD_PROPERTIES.includes(field))
        return {
          hidden: prev.hidden.filter((f) => f !== field),
          shown: [...orderedFields, field, ...notOrderedFields]
        };
      } else {
        return {
          shown: prev.shown.filter((f) => f !== field),
          hidden: [...prev.hidden, field]
        };
      }
    });
  };
  useEffect(() => {
    dispatch({
      type: 'preferences/changeUiPreferences',
      payload: {
        key: 'leadsVisibilitySettings',
        value: fields
      }
    })
  }, [fields]);
  const handleDragStart = (event) => {
    const {active} = event;
    setActiveItem(active.id);  // Set the item being dragged
  };

  const handleDragEnd = (event) => {
    const {active, over} = event;

    if (active.id !== over?.id) {
      setFields((prev) => ({
        ...prev,
        shown: arrayMove(prev.shown, prev.shown.indexOf(active.id), prev.shown.indexOf(over.id))
      }));
    }
    setActiveItem(null); // Reset active item after drag ends
  };

  const filteredShownFields = fields?.shown?.filter((field) => field.toLowerCase().includes(search.toLowerCase())) || []
  const filteredHiddenFields = fields?.hidden?.filter((field) => field.toLowerCase().includes(search.toLowerCase())) || []

  if (hide) return null
  return (
    <div>
        <SearchBar value={search} onSearch={setSearch}/>
        <Divider><F id={'pages.lead.visibilitySettings.shown'} defaultMessage={'Shown'}/></Divider>
        <DndContext
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={filteredShownFields} strategy={verticalListSortingStrategy}>
            <FieldList fields={filteredShownFields} onToggle={handleFieldToggle} isVisible/>
          </SortableContext>

          <DragOverlay>
            {activeItem ? <Property field={activeItem} isVisible hasSort={false} isDragging/>
              : null}
          </DragOverlay>
        </DndContext>

        <Divider><F id={'pages.lead.visibilitySettings.hidden'} defaultMessage={'Hidden'}/></Divider>
        <FieldList fields={filteredHiddenFields} onToggle={handleFieldToggle} isVisible={false}/>
    </div>
  );
}
