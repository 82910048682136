import React from 'react';
import {Tooltip} from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {getLanguageFromUmiLocale} from "@/utils/locale";

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);
dayjs.locale(getLanguageFromUmiLocale())

const PrettyDate = ({ date }) => {

  // Format the date to be relative (e.g., "2 days ago")
  const formattedDate = dayjs(date).fromNow();

  // Optionally, show a tooltip with the exact date
  const exactDate = dayjs(date).format('YYYY-MM-DD HH:mm');

  return (
    <Tooltip title={exactDate}>
      <span>{formattedDate}</span>
    </Tooltip>
  );
};

export default PrettyDate;
