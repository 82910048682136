import {message} from "antd";
import {RequestResponse, ResponseError} from "umi-request";
import get from "lodash/get";

export function getFromAndToDate(date) {
  return {
    from: date.start,
    to: date.end
  };
}


interface RequestCallbacks {
  onError?: (error?: ResponseError) => void,
  onSuccess: (data: any) => void | any,
  onEnd?: () => void,
}

export function* processAPIResponse({response, data}: RequestResponse, {
  onError,
  onSuccess,
  onEnd
}: RequestCallbacks, dataKey?: string) {
  onEnd && onEnd()
  try{
    if (response.status >= 200 && response.status < 300) {
    yield onSuccess(dataKey ? get(data, dataKey) : data);
    } else {
      if (!onError) {
    message.error(data?.message || data?.error?.message);
      } else {
        yield onError(data)
      }
    }
  }catch(e){
    if (!onError) {
      let errorMessage = "Error"
      if (data){
        errorMessage = data.message
      }
      message.error(errorMessage);
    } else {
      yield onError(data)
    }
  }

}
