import {processAPIResponse} from "@/utils/request";
import {fetchUiPreferencesRequest, setUiPreferencesRequest} from "@/services/api/preferences";

export default {
  namespace: 'preferences',

  state: {
    list: {},
    ui: {},
    initialized: false
  },

  effects: {
    * fetchUiPreferences(_, {call, put, select}) {
      const {response, data} = yield call(fetchUiPreferencesRequest);
      return yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'setPreferences',
            payload: data
          })
        },
        * onError(error) {

        }
      })
    },
    * changeUiPreferences({payload = {}}, {call, put, select}) {
      yield put({
        type: 'setPreferences',
        payload: {
          ui: {
            [payload.key]: payload.value
          }
        }
      })
      const {response, data} = yield call(setUiPreferencesRequest, {params: {key: payload.key}, data: payload.value})
      return yield processAPIResponse({response, data}, {
        * onSuccess(data) {
        },
        * onError(error) {
        }
      })
    }
  },
  reducers: {
    setPreferences(state, action) {
      return {
        ...state,
        list: {
          ...state.list,
          ...(action.payload || {}),
        },
        initialized: true,
        ui: {
          ...state.ui,
          ...(action?.payload?.ui)
        }
      };
    }
  }
};
