import React, {useCallback, useState} from 'react';
import {Button, Collapse, Grid, Input, Segmented, Space, Tooltip} from 'antd';
import {
  BarsOutlined,
  FilterOutlined,
  FireOutlined,
  ProjectOutlined,
  ReloadOutlined,
  SearchOutlined
} from '@ant-design/icons';
import {useDispatch, useSelector} from "umi";

import LeadSourceDropdownFilters from "@/components/Filters/LeadSourceDropdownFilters";
import BranchDropdownFilters from "@/components/Filters/BranchDropdownFilters";
import LeadAssigneeDropdownFilters from "@/components/Filters/LeadAssigneeDropdownFilters";
import * as PreferencesSelector from "@/selectors/preferencesSelectors";
import LeadToolbarItem from "@/pages/Leads/LeadToolbarItem";
import {F} from "@/utils/intl";

import styles from "./index.less"
import LeadStatusFilters from "@/components/Filters/LeadStatusFilters";
import LeadViewSettings from "@/components/Kanban/Settings";
import LeadToolbarSorter from "@/components/Kanban/LeadToolbarSorter";
import debounce from "lodash/debounce";

const LeadToolbar = ({request, loading}) => {
  const screens = Grid.useBreakpoint()

  const dispatch = useDispatch()
  const [showMobileFilters, setShowMobileFilters] = useState(false)
  const [searchQuery, setSearchQuery] = useState()

  const kanbanView = useSelector(PreferencesSelector.selectLeadKanbanView)
  const isHeatmap = useSelector(PreferencesSelector.selectIsHeatMap)
  const onHeatMapViewChange = () => {
    dispatch({
      type: 'preferences/changeUiPreferences',
      payload: {
        key: 'isHeatmapList',
        value: {
          isHeatmapList: !isHeatmap,
        },
      },
    });
    dispatch({
      type: 'leads/changeFilter',
      payload: {
        sort: '+modified'
      }
    })
    // Additional logic to apply or remove heatmap styles from the Kanban board
};
  // Create a debounced function for the search
  const handleSearch = useCallback(
    debounce((search) => {
      dispatch({
        type: 'leads/changeFilter',
        payload: {
          search
        }
      })
    }, 500),
    []
  );

  const onSearchChanged = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    handleSearch(value); // This will only call the debounced version of handleSearch
  };
  const toggleLeadView = () => {
    dispatch({
      type: 'preferences/changeUiPreferences',
      payload: {
        key: 'leadListView',
        value: {
          kanbanView: !kanbanView,
        },
      },
    });
  }

  const toggleFilterView = () => {
    setShowMobileFilters(prev => !prev)
  }
  const reload = () => {
    request()
  }
  const search = <Input
    placeholder="Search"
    prefix={<SearchOutlined/>}
    onChange={onSearchChanged}
    value={searchQuery}
    className={styles.searchInput}
  />

  const filters = <Space className={styles.leftFilters} wrap>
    {!screens.md && search}
    <LeadAssigneeDropdownFilters showUnassigned/>
    <BranchDropdownFilters showUnlinked/>
    {/* TODO: Out of scope of 6 weeks due to backend implementation*/}
    {/*<LeadMarketingChannelDropdownFilters/>*/}
    {/*<LeadPriorityDropdownFilters/>*/}
    <LeadSourceDropdownFilters/>
    {!kanbanView && <LeadStatusFilters/>}
  </Space>



  return (
    <div className={styles.leadToolbar}>
      {screens.md && filters}
      <Space className={styles.rightOptions} wrap>
        {screens.md && search}
        {!screens.md && (
          <LeadToolbarItem
            buttonType={showMobileFilters ? 'primary' : ''}
            icon={<FilterOutlined />}
            tooltip={<F id={'pages.lead.toolbar.filters'} />}
            onClick={toggleFilterView}
          />
        )}
        <Segmented
          value={kanbanView ? 'kanban' : 'table'}
          onChange={toggleLeadView}
          options={[
            {
              value: 'table',
              icon: (
                <Tooltip title={<F id={`pages.lead.toolbar.view.table`} />}>
                  <BarsOutlined />
                </Tooltip>
              ),
            },
            {
              value: 'kanban',
              icon: (
                <Tooltip title={<F id={`pages.lead.toolbar.view.kanban`} />}>
                  <ProjectOutlined />
                </Tooltip>
              ),
            },
          ]}
        />
        {kanbanView && (
          <Tooltip title={isHeatmap ? 'Switch to Regular View' : 'Switch to Heatmap'}>
            <Button
              shape="circle"
              icon={<FireOutlined style={{ color: isHeatmap ? 'black' : 'black' }} />}
              onClick={onHeatMapViewChange}
              style={{
                backgroundColor: isHeatmap ? '#45ccd487' : 'orange',
                borderColor: isHeatmap ? '#27ae9d' : 'orange',
              }}
            />
          </Tooltip>
        )}
        <LeadToolbarItem
          icon={<ReloadOutlined />}
          tooltip={<F id={'pages.lead.toolbar.reload'} />}
          onClick={reload}
          buttonProps={{ loading }}
        />
        <LeadToolbarSorter/>
        <LeadViewSettings />
      </Space>
      {!screens.md && (
        <Collapse className={styles.mobileLeadFilters} activeKey={showMobileFilters ? '1' : '0'}>
          <Collapse.Panel key={'1'} header={null}>
            {filters}
          </Collapse.Panel>
        </Collapse>
      )}
    </div>
  );
};

export default LeadToolbar;
