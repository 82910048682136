import React, {useRef, useState} from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import Card from "@/components/Card";
import {Button, Input, Popconfirm, Select, Space} from "antd";
import {CheckOutlined, CloseOutlined, DeleteOutlined, MenuOutlined, PlusOutlined} from "@ant-design/icons";
import {LeadStatusColorPopover} from "@/components/Kanban/StatusManager/LeadStatusColor";
import classNames from "classnames";
import styles from "./index.less";
import {F} from "@/utils/intl";
import {useIntl, useSelector} from "umi";
import * as PreferencesSelector from "@/selectors/preferencesSelectors";

export const LEAD_STATE_TYPES = {
  OPEN: 'OPEN',
  WON: 'WON',
  LOST: 'LOST'
}
const AUTOMATION_LIFECYCLE_EVENT_STATE_MAP = {
  APPOINTMENT_BOOKED: 'APPOINTMENT_BOOKED',
  APPOINTMENT_CANCELLED: 'APPOINTMENT_CANCELLED',
  MANUAL: 'MANUAL',
}

const EditableStatusCard = ({
                              id,
                              label,
                              color,
                              automationEvent,
                              onAutomationEventChange,
                              onEdit,
                              dragPlaceholder,
                              onColorSelect,
                              draggable = true,
                              isNew,
                              onDelete,
                              state_type,
                              onLeadStateTypeChange
                            }) => {

  const intl = useIntl()

  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(label);
  const [colorPopoverOpen, setColorPopoverOpen] = useState<boolean>(false);
  const [newStatusColor, setNewStatusColor] = useState<boolean>(color);
  const inputRef = useRef(null);


  const automationEvents = [{
    id: AUTOMATION_LIFECYCLE_EVENT_STATE_MAP.MANUAL,
    title: <F id={'pages.lead.status.lifecycle.manual'} defaultMessage={'Manual'}/>
  }, {
    id: AUTOMATION_LIFECYCLE_EVENT_STATE_MAP.APPOINTMENT_BOOKED,
    title: <F id={'pages.lead.status.lifecycle.appointmentBooked'} defaultMessage={'On Appointment Booked'}/>
  }, {
    id: AUTOMATION_LIFECYCLE_EVENT_STATE_MAP.APPOINTMENT_CANCELLED,
    title: <F id={'pages.lead.status.lifecycle.appointmentCancelled'} defaultMessage={'On Appointment Cancelled'}/>
  }]

  const stateTypes = useSelector(PreferencesSelector.selectStateTypesList)

  const {attributes, listeners, setNodeRef, transform, transition, isDragging} =
    useSortable({id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: (!isNew && isDragging) ? 0.5 : 1,
    marginBottom: "8px",
  };

  const handleColorPopoverChange = (newOpen) => {
    setTimeout(() => {
      setColorPopoverOpen(newOpen)
    }, 100)
  }
  const handleDoubleClick = () => {
    if (isEditing) return
    setIsEditing(true);
    if (isNew) setValue("")
  };
  const handleClick = isNew ? handleDoubleClick : () => {
  }

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = (e) => {
    if (isNew) return false

    if (colorPopoverOpen) {
      e.preventDefault()
      inputRef.current?.focus()
      return
    }
    setIsEditing(false);
    onEdit(id, value);
  };

  const handlePressEnter = (e) => {
    if (isNew) {
      onEdit(null, value)
      setIsEditing(false)
      return
    }

    handleBlur(e)
  };

  const getColor = () => isNew ? newStatusColor : color
  const handleColorSelect = (id, color) => {
    if (isNew) setNewStatusColor(color)
    onColorSelect(id, color)
  }

  const showDragHandle = !isEditing && draggable && !dragPlaceholder && !isNew
  const showAutomationEventSelector = !isEditing && !isNew && !dragPlaceholder

  const input = <Input size="small"
                       value={value}
                       onPressEnter={handlePressEnter}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       ref={inputRef}
                       autoFocus/>
  return (
    <div ref={setNodeRef} style={style} className={styles.eachStatus}>
      <Card className={classNames({
        [styles.newStatusCard]: isNew
      })} mini onDoubleClick={handleDoubleClick} onClick={handleClick}>
        <div className={styles.editableStatusInner}>
          <div className={styles.statusLeft}>
            {isNew && !isEditing && <PlusOutlined className={styles.addStatusIcon}/>}
            {showDragHandle && <Button
              {...listeners} // Drag listeners attached only to the handle
              {...attributes}
              type="text"
              size="small"
              icon={<MenuOutlined/>}
              onClick={(e) => e.stopPropagation()} // Prevent click from propagating
            />}
            {(!isNew || isEditing) &&
              <LeadStatusColorPopover onOpenChange={handleColorPopoverChange} defaultOpen={isNew}
                                      onSelect={(newColor) => handleColorSelect(id, newColor)}
                                      color={getColor()}/>}
            {isEditing ? (!isNew ? input : <Space.Compact>
                {input}
                <Button onClick={(e) => {
                  e.stopPropagation()
                  setIsEditing(false)
                  onEdit(null, value)
                }} type="primary" icon={<CheckOutlined/>}/>
                <Button onClick={(e) => {
                  e.stopPropagation()
                  setIsEditing(false)
                }} type={"ghost"} icon={<CloseOutlined/>}/>
              </Space.Compact>) :
              <span>{isNew ? intl.formatMessage({
                id: 'pages.lead.status.new',
                defaultMessage: 'Add Status'
              }) : value}</span>}
          </div>

          {
            showAutomationEventSelector && <div className={styles.statusRight}>
              <div className={styles.automationEventSelectContainer}>
                <span className={styles.automationEventSelectLabel}><F id={'pages.lead.status.selectDealState'}
                                                                       defaultMessage={'Deal'}/></span>
                <span><Select value={state_type || '-1'}
                              onSelect={(newLeadStateType) => onLeadStateTypeChange(id, newLeadStateType)}
                              bordered={false} size={"small"}>
                  {stateTypes.map(dealState => <Select.Option value={dealState.id}
                                                              key={dealState.id}>{dealState.title}</Select.Option>)}
                </Select></span>
              </div>
              {/*<div className={styles.automationEventSelectContainer}>*/}
              {/*  <span className={styles.automationEventSelectLabel}><F id={'pages.lead.status.selectLifeCycleAutomation'}*/}
              {/*                                                         defaultMessage={'Update when'}/> </span>*/}
              {/*  <span><Select value={automationEvent || '-1'}*/}
              {/*                onSelect={(newAutomationEvent) => onAutomationEventChange(id, newAutomationEvent)}*/}
              {/*                bordered={false} size={"small"}>*/}
              {/*    {automationEvents.map(automationEvent => <Select.Option value={automationEvent.id}*/}
              {/*                                                            key={automationEvent.id}>{automationEvent.title}</Select.Option>)}*/}
              {/*  </Select></span>*/}
              {/*</div>*/}
            </div>
          }
        </div>
      </Card>
      {draggable && !isNew && !dragPlaceholder &&
        <Popconfirm placement={"topRight"} onConfirm={() => onDelete(id)} title={<F id={'pages.lead.status.removeStatusConfirmation'} defaultMessage={"Are you sure to remove this status?"}/> }>
          <Button size={"small"} type={"text"} icon={<DeleteOutlined/>}></Button>
        </Popconfirm>}
    </div>
  );
};

export default EditableStatusCard
