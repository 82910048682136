export const transformObjectToPayloadFields = (inputObject) => {
    const payloadFields = Object.keys(inputObject).map((key) => {
      const parts = key.split('.');
      const label = parts[parts.length - 1];
      return {
        label,
        key,
        value: inputObject[key],
      };
    });
  
    return payloadFields;
  };
  export const transformObject = (inputObject) => {
    const resultArray = Object.keys(inputObject).map((key) => ({
      target_field: key,
      sources: inputObject[key],
    }));
    return resultArray;
  };