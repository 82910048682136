import get from "lodash/get";

export const selectLeadsVisibilitySettings = (state: DefaultRootState) => state?.preferences?.ui?.leadsVisibilitySettings
export const selectShownLeadsProperties = (state: DefaultRootState) => state?.preferences?.ui?.leadsVisibilitySettings?.shown || []
export const selectHiddenLeadsProperties = (state: DefaultRootState) => state?.preferences?.ui?.leadsVisibilitySettings?.hidden || []
export const selectAllLeadsProperties = (state: DefaultRootState) => selectShownLeadsProperties(state).concat(selectHiddenLeadsProperties(state))
export const selectLeadKanbanView = (state: DefaultRootState) => state?.preferences?.ui?.leadListView?.kanbanView
export const selectIsHeatMap = (state: DefaultRootState) =>  state?.preferences?.ui?.isHeatmapList?.isHeatmapList
export const selectIsPreferencesInitialized = (state: DefaultRootState) =>  state?.preferences?.initialized

export const selectPriorityList = (state: DefaultRootState) => get(state, 'preferences.list.lead.priorities', [])
export const selectEmailAutomationTypesList = (state: DefaultRootState) => get(state, 'preferences.list.lead.email_automation_types', [])
export const selectStateTypesList = (state: DefaultRootState) => {
  const stateTypes = get(state, 'preferences.list.lead.states_types', {})
  return Object.keys(stateTypes).map(stateType => ({
    id: stateTypes[stateType].value,
    title: stateTypes[stateType].label,
  }))
}

