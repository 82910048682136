import React, {useState} from "react";

import {EditOutlined} from "@ant-design/icons";
import {Input} from "antd";
import {useDispatch} from "umi";

import styles from "./index.less"
import get from "lodash/get";
import classNames from "classnames";

const EditableTextField = ({children, editable = true, textarea = false, lead, property}) => {
  const [isEditing, setIsEditing] = useState(false)

  const dispatch = useDispatch()
  const onSubmit = e => {
    setIsEditing(false)

    const value = e.target.value
    if (value !== lead[property]){
      dispatch({
        type: 'leads/edit',
        payload: {
          leadId: lead.id,
          [property]: value
        }
      })
    }

  }
  if (!editable) return children
  if (!isEditing) return <div className={classNames(styles.editableTextWrapper, styles.fieldContainer)}>
    {children}
    <span className={styles.editIcon} onClick={() => setIsEditing(true)}><EditOutlined/></span>
  </div>

  const InputComponent = textarea ? Input.TextArea : Input
  return <InputComponent size={"small"} className={styles.fieldContainer} autoFocus defaultValue={get(lead, property)} onBlur={onSubmit} onPressEnter={onSubmit}/>
}

export default EditableTextField
