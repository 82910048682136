import * as React from "react"
import {useEffect} from "react"
import LeadDropdownFilter from "@/components/Filters/LeadDropdownFilter";
import {F} from "@/utils/intl";
import {FlagFilled} from "@ant-design/icons";
import {Space} from "antd";
import {useDispatch, useSelector} from "umi";
import * as PreferencesSelectors from "@/selectors/preferencesSelectors"

const LeadPriorityDropdownFilters = ({ onSelectionChange, ...props }) => {

  const priorities = useSelector(PreferencesSelectors.selectPriorityList)
  const dispatch = useDispatch()

  useEffect(() => {
    if (priorities.length === 0){
      dispatch({
        type: 'preferences/fetchUiPreferences'
      })
    }
  }, []);

  const LEAD_PRIORITY = (priorities || []).map(priority => ({
    icon: <FlagFilled style={{color: priority.color}}/>,
    id: priority.value,
    name: priority.label
  }))

  const optionRender = (priority) => (
    <Space>
      {priority.icon}
      <span>{priority.name}</span>
    </Space>
  );

  const handleSelectionChange = (selectedValues) => {
    onSelectionChange && onSelectionChange(selectedValues);
  };

  return (
    <LeadDropdownFilter
      placeholder={<F id={'pages.leads.filters.priority.placeholder'} defaultMessage={'Priority'}/>}
      optionRender={optionRender}
      filterKey={'priority'}
      options={LEAD_PRIORITY}
      onChange={handleSelectionChange}
      {...props}
      selectProps={{
        showSearch: false,
        style: {
          minWidth: 120
        },
        ...(props.selectProps || {})
      }}
    />
  );
};

export default LeadPriorityDropdownFilters;
