export const DEALS = 'deals';
export const REVENUE = 'revenue';
export const APPOINTMENT = 'appointment';
export const CONVERSION_RATE = 'conversion_rate';
export const LEADS = 'Leads';

export const ALL_METRICS = [LEADS, REVENUE, CONVERSION_RATE];

export const GROUP_BY_BRANCH = 'branch';
export const GROUP_BY_STATUS = 'lead_state';
export const GROUP_BY_SERVICE = 'service';
export const GROUP_BY_MARKETING_CHANNEL = 'marketing_channel';
export const GROUP_BY_LOCATION = 'location';
export const GROUP_BY_ASSIGNEE = 'assignee';

export const ALL_GROUP_BY_OPTIONS = [GROUP_BY_BRANCH, GROUP_BY_MARKETING_CHANNEL, GROUP_BY_ASSIGNEE];