export const sortByKey = (array, key) => {
  return array.sort(function (a, b) {
    const x = a[key];
    const y = b[key];
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

export const emptyFunction = () => {};


// TODO: Enhance Duplicate Code with Lightweight Launcher
export const extractPathVariableParts = (schema = "") => {
  const splittedBySlash = schema.split("/").filter(part => part.length > 0);
  return splittedBySlash.map(part => {
    if (part.startsWith(":")) {
      return {
        type: "param",
        value: part.split(":")[1]
      }
    }
    return {
      type: "const",
      value: part
    }
  });
}

type IURLParamBuilderOptions = {
  params?: Record<string, unknown>
  queryString?: Record<string, unknown>,
  root?: string
}

const AddPathVariableFromParams = (params: Record<string, unknown>, part: { type?: string, value?: any }) => params?.[part.value] ? `/${params?.[part.value]}` : ""

const addPathVariableToURL = (url, schemaParts: { type?: string, value?: any }[], params: Record<string, unknown>) => {
  for (const part of schemaParts.filter(part => part.type && part.value)) {
    url += part.type === "param" ? AddPathVariableFromParams(params, part) : `/${part.value}`
  }
  return url

}
const addQueryStringToUrl = (url: string, queryString: Record<string, unknown>) => {
  const queryStringEntries = Object.entries(queryString).filter(([_, value]) => value !== undefined);
  const queryStringString = queryStringEntries.map(([key, value], index) => `${index === 0 ? '?' : '&'}${key}=${value}`).join('');
  url += queryStringString;
  return url

}
const addRootToUrl = (root: string) => {
  if (!root || typeof root !== "string") {
    root = "/";
  }
  if (root?.endsWith('/'))
    root = root?.substring(0, root?.length - 1)

  return root

}

export const URLParamBuilder = (path: string, options: IURLParamBuilderOptions = {}, separateQueryStringsWithSlash = true) => {
  const { queryString = {}, params = {} } = options
  let { root } = options;

  let url = addRootToUrl(root);

  const schemaParts = extractPathVariableParts(path)
  url = addPathVariableToURL(url, schemaParts, params)
  url += separateQueryStringsWithSlash ? "/" : "";

  url =  addQueryStringToUrl(url, queryString)
  return url;
}


export function getExportURL(list: any): string | boolean {
  try {
    return list.links.export || false
  }
  catch(e){
    return false
  }
}

export function capitalizeFirstLetter(str = "") {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeAllLetters(str = "") {
  return str.toUpperCase()
}


export function getInitials(string = "", count = 2){
  try{
    return string.split(" ").slice(0,count).map(str => capitalizeFirstLetter(str[0])).join("")
  }catch(e){
    return "CV"
  }
}
