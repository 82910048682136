import {CSS} from "@dnd-kit/utilities";


function hexToRgba(hex, opacity) {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const getKanbanColumnStyles = ({transform, dragOverlay, transition, isDragging, color}) => {
  const getColumnHeight = () => document.getElementsByClassName("kanban-column")[0].clientHeight

  return {
    transform: CSS.Translate.toString(transform),
    height: dragOverlay ? `${getColumnHeight() + "px"}` : null,
    transition,
    opacity: isDragging ? 0.5 : 1,
    background: hexToRgba(color, 0.1),
    boxShadow: dragOverlay
      ? "0 0 0 calc(1px / 1) rgba(63, 63, 68, 0.05), -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)"
      : "",
    border: dragOverlay
      ? "1px solid rgba(64, 150, 255, 1)"
      : "none", // 1px solid rgba(64, 150, 255, 1)
    //cursor: dragOverlay ? "grabbing" : "grab",
    //transform: dragOverlay ? 'rotate(0deg) scale(1.02)' : 'rotate(0deg) scale(1.0)'
    touchAction:
      "ontouchstart" in window ||
      navigator.MaxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
        ? "manipulation"
        : "none"
  }
}


export const getKanbanCardStyles = ({transform, transition, dragOverlay, isDragging}) => {
  return {
    transform: CSS.Translate.toString(transform),
      transition,
      opacity: isDragging ? 0.5 : 1,
    boxShadow: dragOverlay
    ? "0 0 0 calc(1px / 1) rgba(63, 63, 68, 0.05), -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)"
    : "",
    border: dragOverlay
    ? "1px solid rgba(64, 150, 255, 1)"
    : "1px solid #dcdcdc", // 1px solid rgba(64, 150, 255, 1)
    cursor: dragOverlay ? "grabbing" : "grab",
    //transform: dragOverlay ? 'rotate(0deg) scale(1.02)' : 'rotate(0deg) scale(1.0)'
    touchAction:
    window.PointerEvent ||
    "ontouchstart" in window ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
      ? "manipulation"
      : "none"
  };
}
