import React, { useState } from 'react';
import { Card, Button, Typography, Space } from 'antd';
import { UserOutlined, FileOutlined, ApiOutlined } from '@ant-design/icons';
import CSVLeadSourceImport from './LeadSource/Index';
import WebhookLeadSourceConfig from './Webhook';
import AddManualLead from './ManualLead';
import { useDispatch, useSelector, FormattedMessage as F } from 'umi';
import { LEAD_SOURCE_TYPE_CSV, LEAD_SOURCE_TYPE_MANUAL, LEAD_SOURCE_TYPE_WEBHOOK } from './constants';

const { Text } = Typography;

const LeadSourceSwitcher = () => {
  const [selectedSource, setSelectedSource] = useState('manual');
  const { visible } = useSelector(({ leads: { addLead } }: DefaultRootState) => ({
    visible: addLead?.drawer?.visible,
  }));
  const handleSourceChange = (source) => {
    setSelectedSource(source);
  };

  const buttonStyle = (source) => ({
    backgroundColor: selectedSource === source ? '#F8E9E2' : '#E8E8E8',
    borderColor: selectedSource === source ? '#FA7F46' : '#D9D9D9',
    color: selectedSource === source ? '#FA7F46' : '#595959',
    padding: '8px 16px',
    borderRadius: '8px',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    width: '150px',
    height: '50px',
  });

  return (
    <Card
      title={<Text style={{ fontSize: '16px', fontWeight: '500' }}>Select Lead Source</Text>}
      bordered={false}
      style={{ width: '100%', marginBottom: '20px' }}
    >
      <Space size="large" style={{ display: 'flex' }}>
        <Button
          type="default"
          style={buttonStyle('manual')}
          onClick={() => handleSourceChange('manual')}
        >
          <UserOutlined />
          Manual
        </Button>
        <Button
          type="default"
          style={buttonStyle(LEAD_SOURCE_TYPE_CSV)}
          onClick={() => handleSourceChange(LEAD_SOURCE_TYPE_CSV)}
        >
          <FileOutlined />
          CSV
        </Button>
        <Button
          type="default"
          style={buttonStyle('webhook')}
          onClick={() => handleSourceChange('webhook')}
        >
          <ApiOutlined />
          Webhook
        </Button>
      </Space>
      <div style={{ marginTop: '20px' }}>
        {/* Render content based on selected lead source */}
        {selectedSource === LEAD_SOURCE_TYPE_MANUAL && visible === true && (
          <div>
            <AddManualLead />
          </div>
        )}
        {selectedSource === LEAD_SOURCE_TYPE_CSV && visible && (
          <div>
            {' '}
            <CSVLeadSourceImport />
          </div>
        )}
        {selectedSource === LEAD_SOURCE_TYPE_WEBHOOK && visible && (
          <div>
            <WebhookLeadSourceConfig />
          </div>
        )}
      </div>
    </Card>
  );
};

export default LeadSourceSwitcher;
