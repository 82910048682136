import {Avatar, Tooltip} from "antd";
import React from "react";

import {LeadModel} from "@/typings/models/Lead";
import LeadContextMenu from "@/components/Lead/LeadContextMenu";

const LeadAssigneeOverview = ({lead, isTable}: {lead: LeadModel, isTable?: boolean}) => {
  const assignee = lead.assignee
  const assigneeName = assignee ? `${assignee.firstname} ${assignee.lastname}` : ''
  const content = assignee ?
        <Tooltip placement={"topLeft"} title={assigneeName}>
          <Avatar style={{cursor: 'pointer'}} src={assignee.avatar_link} size={"small"}>{assigneeName[0]}</Avatar>
        </Tooltip>
  : null

  return !isTable ? <LeadContextMenu lead={lead} actionsFilter={['assign']} assignContent={content}/> : <div style={{minWidth: 100}}>
    <LeadContextMenu lead={lead} actionsFilter={['assign']} assignContent={content}/>
  </div>
}

export default LeadAssigneeOverview
