import React from "react";

import {LeadModel} from "@/typings/models/Lead";
import EditableTextField from "@/components/Lead/EditableTextField";
import isEmpty from "lodash/isEmpty";

const LeadEmail = ({lead, readonly}: {lead: LeadModel, readonly: boolean}) => {
  const {customer} = lead
  if (isEmpty(customer)) return null
  return <EditableTextField editable={!readonly} lead={lead} property={'customer.email'}>
    <a href={`mailto:${customer.email}`}>{customer.email}</a>
  </EditableTextField>
}

export default LeadEmail
