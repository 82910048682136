import {
  Button,
  Input,
  Form,
  Select,
  Steps,
  Divider,
  Col,
  Row,
  Typography
} from "antd";
import { useEffect } from "react";

const { Option } = Select;
const MapColumnsSegment = ({
  mappingForm,
  handleMappingSubmit,
  systemFields,
  config,
  isNew,
  csvHeaders,
  customFields,
  handleCustomFieldNameChange,
  handleCustomFieldCsvHeadersChange,
  addCustomField,
  nextStep,
  prevStep,
  onClose,
  setDelimiter,
  delimiter,
}) => {
  useEffect(() => {
    if (!isNew && config) {
      const mappings = config?.configuration?.mappings?.reduce((acc, mapping) => {
        acc[mapping.target_field] = mapping.sources;
        return acc;
      }, {});
      mappingForm.setFieldsValue({ ...mappings });
    }
  }, [isNew, config, mappingForm]);

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 24, width: '100%' }}>
        <Col span={4}>
          <Form.Item style={{ width: '100%' }} label="Delimiter" style={{ marginBottom: 10 }}>
            <Select
              value={delimiter}
              onChange={setDelimiter}
              options={[
                { value: ',', label: 'Comma (,)' },
                { value: ';', label: 'Semicolon (;)' },
                { value: '\t', label: 'Tab (\\t)' },
                { value: ' ', label: 'Space ( )' },
                { value: '|', label: 'Pipe (|)' },
              ]}
            ></Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Form layout="vertical" form={mappingForm} onFinish={handleMappingSubmit}>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          {(systemFields?.default || [])?.map((field) => (
            <Col span={12} key={field}>
              <Form.Item
                key={field.key}
                label={field.label}
                name={field.key}
                rules={[{ required: field.required, message: `${field.label} is required` }]}
                style={{ marginBottom: 20 }}
                required={field.required}
                tooltip={field.tooltip}
              >
                <Select
                  mode="multiple"
                  placeholder={`Select CSV columns for "${field.label}"`}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {csvHeaders.map((header) => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Divider />
        <Button type="dashed" onClick={addCustomField} style={{ width: '100%', marginBottom: 20 }}>
          Add Custom Field
        </Button>
        <Row gutter={16} style={{ marginBottom: 24 }}>
          {[...systemFields?.meta, ...customFields].map((field) => (
            <>
              <Col span={12} key={field.key}>
                <Form.Item label="Custom Field Name" style={{ marginBottom: 10 }}>
                  <Input
                    placeholder="Enter custom field name"
                    value={field.name ?? field.key}
                    onChange={(e) => handleCustomFieldNameChange(field.key, e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12} key={field}>
                <Form.Item label="Map to CSV Columns" style={{ marginBottom: 10 }}>
                  <Select
                    mode="multiple"
                    placeholder="Select CSV columns"
                    value={field.csvHeaders}
                    onChange={(value) => handleCustomFieldCsvHeadersChange(field.key, value)}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {csvHeaders.map((header) => (
                      <Option key={header} value={header}>
                        {header}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          ))}
        </Row>
        <Form.Item>
          <Button type="default" onClick={onClose} style={{ margin: '0 8px' }}>
            Cancel
          </Button>

          <Button style={{ margin: '0 8px' }} onClick={prevStep}>
            Previous
          </Button>

          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default MapColumnsSegment;