import * as React from 'react';
import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, Input, Popconfirm } from 'antd';
import { useDispatch, useSelector, FormattedMessage as F, useIntl } from 'umi';
import { LEAD_SOURCE_TYPE_WEBHOOK } from '../constants';
import NewWebhookSource from './NewWebHookSource';
const { Search } = Input;

const WebhookLeadSourceConfig: React.FC = () => {
  const [isNewSourceModalVisible, setIsNewSourceModalVisible] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isNew, setIsNew] = useState(undefined);
  const { leadSourceList } = useSelector((state: DefaultRootState) => ({
    leadSourceList: state.journey.list,
    leadConfig: Object.values(state.journey.config)?.[0],
    loading: state.loading.effects['journey/fetchConfig'],
  }));
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredLeadSourceList, setFilteredLeadSourceList] = useState([]);
  React.useEffect(() => {
    if (searchQuery) {
      const filteredList = leadSourceList.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredLeadSourceList(filteredList);
    } else {
      setFilteredLeadSourceList(leadSourceList);
    }
  }, [searchQuery, leadSourceList]);

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const fetchLeadConfig = (id) => {
    dispatch({
      type: 'leads/fetchLeadSource',
      payload: { id },
    });
  };
  const deleteLeadSource = (id:string, resolve: Function) => {
    dispatch({
      type: 'leads/deleteLeadSource',
      payload:{
        id,
        cb: resolve,
        intl,
      }
    });
  };

  const leadSourceListColumns = [
    {
      title: <F id={'pages.leadsources.name'} defaultMessage={'Name'} />,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <F id={'pages.customers.actions'} defaultMessage={'Name'} />,
      key: 'action',
      render: (record) => (
        <div>
          <Button
            type="link"
            style={{ color: '#27AE9D' }}
            onClick={() => {
              fetchLeadConfig(record.hashId);
              setIsNew(false);
              setIsNewSourceModalVisible(true);
            }}
          >
            Edit Configuration
          </Button>
          <Popconfirm
            onConfirm={() => new Promise((resolve) => deleteLeadSource(record.hashId, resolve))}
            placement={'topRight'}
            title={
              <F
                id={'pages.leadsources.removeConfirm'}
                defaultMessage={'Are you sure to remove this lead source?'}
              />
            }
          >
            <Button type="link" style={{ color: '#27AE9D' }}>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div>
        {isNewSourceModalVisible && (
          <NewWebhookSource
            open={isNewSourceModalVisible}
            onClose={() => {
              dispatch({
                type: 'leads/saveLeadSourceDetail',
                payload: {},
              });
              setIsNewSourceModalVisible(false)
            }}
            isNew={isNew}
          />
        )}
        {!isNewSourceModalVisible && (
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 25,
              }}
            >
              <Search
                placeholder="Search"
                allowClear
                onSearch={handleSearch}
                style={{ width: 200 }}
              />

              <Button
                type="link"
                style={{ color: '#27AE9D' }}
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsNew(true);
                  setIsNewSourceModalVisible(true);
                }}
              >
                Setup New Webhook
              </Button>
            </div>
            <Table
              columns={leadSourceListColumns}
              dataSource={filteredLeadSourceList?.filter(
                (source) => source.type === LEAD_SOURCE_TYPE_WEBHOOK,
              )}
              pagination={{
                pageSize: 5, // Number of rows per page
                showSizeChanger: true, // Allow the user to change the page size
                pageSizeOptions: ['10', '20', '50'], // Options for page size
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WebhookLeadSourceConfig;
