import * as React from "react"
import {useDispatch} from "umi";

import {LeadModel} from "@/typings/models/Lead";
import LeadPriorityDropdownFilters from "@/components/Filters/LeadPriorityDropdownFilters";
import styles from "./index.less"

const LeadPriority = ({lead}: {lead: LeadModel}) => {
  const dispatch = useDispatch()
  const onChangeStatus = (newPriority) => {
    dispatch({
      type: 'leads/edit',
      payload: {
        priority: newPriority?.value || newPriority,
        leadId: lead.id
      }
    })
  }

  return <LeadPriorityDropdownFilters className={styles.leadPriorityContainer} selectProps={{bordered: false, allowClear: false}} filterMode={"select"} onChange={onChangeStatus} value={lead.priority?.value}/>
}

export default LeadPriority
