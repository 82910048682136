import {Table} from 'antd';
import {useIntl} from 'umi';

const PerformanceTable = ({ data = [], loading=false }) =>{
  const intl = useIntl();
  const columns = [
    {
      title: '',
      dataIndex: ['label', 'title'],
      key: 'label',
      sorter: (a, b) => a.label?.title.localeCompare(b.label?.title),
      filters: data?.map((item) => ({
        text: item.label.title,
        value: item.label.filter_id,
      })),
      onFilter: (value, record) => record.label.filter_id === value,

    },
    {
      title: intl.formatMessage({ id: 'pages.leads.revenue', defaultMessage: 'Revenue' }),
      dataIndex: ['values', 'revenue'],
      key: 'revenue',
      sorter: (a, b) => a.values.revenue - b.values.revenue,
    },
    {
      title: intl.formatMessage({ id: 'pages.leads.leads', defaultMessage: 'Leads' }),
      dataIndex: ['values', 'count'],
      key: 'deals',
      sorter: (a, b) => a.values.deals - b.values.deals,
    },
    {
      title: intl.formatMessage({
        id: 'pages.leads.conversion_Rate',
        defaultMessage: 'Conversion Rate',
      }),
      dataIndex: ['values', 'conversion_rate'],
      key: 'conversionRate',
      sorter: (a, b) => a.values.conversion_rate - b.values.conversion_rate,
    },
  ];

    return (
      <Table
        columns={columns}
        dataSource={data}
        rowKey="label"
        pagination={false}
        loading={loading}
        size={"small"}
      />
    );};

export default PerformanceTable;
