import * as React from "react"

import {LeadModel} from "@/typings/models/Lead";
import LeadStatusDropdownFilter from "@/components/Filters/LeadStatusFilters";
import {useDispatch} from "umi";

const LeadStatus = ({lead, isTable}: {lead: LeadModel, isTable?: boolean}) => {
  if (!isTable) return null
  const dispatch = useDispatch()
  const onChangeStatus = (newStatus) => {
    dispatch({
      type: 'leads/edit',
      payload: {
        lead_state_id: newStatus,
        leadId: lead.id
      }
    })
  }
  return <LeadStatusDropdownFilter selectProps={{bordered: false, allowClear: false}} filterMode={"select"} onChange={onChangeStatus} value={lead.lead_state?.id}/>
}

export default LeadStatus
