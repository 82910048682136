import { useState } from 'react';
import {

  Button,
  Switch,
  Form,
  Popconfirm,
  Tag,
} from 'antd';
import Table from "@/components/Table";
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useIntl, useSelector,useDispatch , FormattedMessage as F} from 'umi';
import NewAutomation from './NewAutomation';

import * as AutomationSelectors from "@/selectors/automationSelector";
import MyPageContainer from '@/components/MyPageContainer';
const TRACKING_LABEL = 'Email Automation';
const REDUX_ACTION = 'automation/fetchEmailAutomationList'
const AutomationList = () => {
  const intl = useIntl();
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editorContent, setEditorContent] = useState('');
  const [currentAutomation, setCurrentAutomation] = useState(null);
  const [updatingRecord, setUpdatingRecord] = useState(null);
  const { automations, loading, users } = useSelector((state: DefaultRootState) => ({
    automations: AutomationSelectors.selectEmailAutomationList(state),
    loading: state.loading.effects[REDUX_ACTION],
    users: state.user?.list,
  }));
  const handleAddAutomation = () => {
    setCurrentAutomation(null);
    showModal();
  };

  const handleEditAutomation = (automation) => {
    setCurrentAutomation(automation);
    showModal();
  };


  const fetchData = (payload?: any) => {
    dispatch({
      type: REDUX_ACTION,
    });
  };

  const create = (automation) => {
    dispatch({
      type: 'automation/createEmailAutomation',
      payload:{...automation,intl}
    });
  };

  const update = (automation) => {
    setUpdatingRecord({ [automation.id]: { loading: true, error: '' } }); 
    dispatch({
      type: 'automation/editEmailAutomation',
      payload: {
        cb: (data) => {
          setUpdatingRecord((prev) => ({
            ...prev,
            [automation.id]: { loading: false, error: data.message },
          }));
        },
        intl,
        id: automation.id,
        ...automation,
        automation_type: automation.automation_type?.key,
      },
    });
  };
  const deleteAutomation = (id:string, resolve: Function) => {
    dispatch({
      type: 'automation/deleteEmailAutomation',
      payload:{
        id,
        cb: resolve,
        intl,
      }
    });
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setIsModalVisible(false);
        if (currentAutomation) update(values);
        else create(values);
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'pages.emailAutomation.name', defaultMessage: 'Name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: 'pages.emailAutomation.trigger', defaultMessage: 'Type' }),
      dataIndex: ['automation_type', 'label'],
      key: 'automation_type',
    },
    {
      title: intl.formatMessage({ id: 'pages.emailAutomation.status', defaultMessage: 'Status' }),
      dataIndex: 'from_state_id',
      key: 'from_state_id',
      render: (text, record) => (
        <div>
          <Tag color={record['from_state']?.color}>{record['from_state']?.label}</Tag>
          {record['to_state'] && (
            <>
              <span style={{ margin: '0 8px' }}>to</span>
              <Tag color={record['to_state']?.color}>{record['to_state']?.label}</Tag>
            </>
          )}
        </div>
      ),
    },
    {
      title: intl.formatMessage({ id: 'pages.emailAutomation.active', defaultMessage: 'Active' }),
      dataIndex: 'is_active',
      key: 'is_active',
      render: (active, record) => (
        <Switch
          checked={record.is_active}
          onChange={() => {
            update({ ...record, is_active: !record.is_active });
          }}
          loading={updatingRecord?.[record.id]?.loading === true}
        />
      ),
    },
    {
      title: intl.formatMessage({ id: 'pages.emailAutomation.actions', defaultMessage: 'Actions' }),
      key: 'actions',
      render: (text, item) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button type="link" onClick={() => handleEditAutomation(item)}>
            <EditOutlined />
          </Button>
          <Popconfirm
            onConfirm={() => new Promise((resolve, reject) => deleteAutomation(item.id, resolve))}
            placement={'topRight'}
            title={
              <F
                id={'pages.automations.removeConfirm'}
                defaultMessage={'Are you sure to remove this automation?'}
              />
            }
          >
            <a>
              <DeleteOutlined disabled={!item?.is_deletable} />
            </a>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <MyPageContainer label={TRACKING_LABEL} extraProps={{ exportTable: {}, hasRangePicker: false }}>
      <Table
        search={false}
        columns={columns}
        dataSource={automations.data ?? []}
        request={fetchData}
        rowKey="id"
        total={automations.total}
        links={automations.links}
        // loading={loading}
        toolBarRender={() => (
          <Button type="primary" onClick={handleAddAutomation}>
            <PlusOutlined />{' '}
            {intl.formatMessage({
              id: 'pages.emailAutomation.newAutomation',
              defaultMessage: 'New Automation',
            })}
          </Button>
        )}
      />
      <NewAutomation
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        form={form}
        editorContent={editorContent}
        setEditorContent={setEditorContent}
        automation={currentAutomation}
      />
    </MyPageContainer>
  );
};

export default AutomationList;