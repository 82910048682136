import React, {useCallback, useEffect, useRef, useState} from "react";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  getFirstCollision,
  MeasuringStrategy,
  MouseSensor,
  pointerWithin,
  rectIntersection,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {arrayMove, horizontalListSortingStrategy, SortableContext} from "@dnd-kit/sortable";
import update from "immutability-helper";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import {useDispatch} from "umi";
import get from "lodash/get";
import omit from "lodash/omit";

import LeadColumn from "@/components/Kanban/LeadColumn";
import LeadCard from "@/components/Kanban/LeadCard";
import SkeletonKanbanBoard from "@/components/Kanban/SkeletonKanbanBoard";
import {LeadGroupedModel} from "@/typings/models/Lead";

import ClientOnlyPortal from "./ClientOnlyPortal";
import styles from "./styles.less"
import {Spin} from "antd";

type ILeadColumn = {
  id: number,
  order: number,
  name: string,
  color: string
}
type ILeadKanbanBoardProps = {
  groupedLeads: LeadGroupedModel[];
  columns: ILeadColumn[];
  groupId?: string;
  loading?: boolean;
  isHeatmap?: boolean;
  request: () => void;
};
export default function LeadKanbanBoard({
  groupedLeads,
  loading,
  request,
  groupId = 'id',
}: Readonly<ILeadKanbanBoardProps>) {
  const [items, setItems] = useState({});
  const [containers, setContainers] = useState([]);
  const [columns, setColumns] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [moveHistory, setMoveHistory] = useState({});
  const lastOverId = useRef(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId) : false;
  const [clonedItems, setClonedItems] = useState(null);


  const dispatch = useDispatch();
  useEffect(() => {
    if (request) request();
  }, []);

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  useEffect(() => {
    if (groupedLeads) {
      const _columns = groupedLeads
        .map((groupedLead) => ({
          id: groupedLead.filter_id,
          title: groupedLead.title,
          ...(groupedLead.meta || {}),
          ...omit(groupedLead, ['data']),
        }))
        .filter((groupedLead) => groupedLead.id !== 'null');
      let columnsRepresentation = {};
      // Sort the columns by order in ascending order
      _columns.sort((a, b) => a.order - b.order);

      // Initialize empty arrays for each column using `reduce`
      columnsRepresentation = _columns.reduce((acc, c) => {
        const leads = groupedLeads.find((leadGroup) => leadGroup.filter_id == c[groupId]);
        acc[`column-${c[groupId]}`] = leads?.data || [];
        return acc;
      }, {});
      setColumns(_columns);
      setItems(columnsRepresentation);
      setContainers(Object.keys(columnsRepresentation));
    }
  }, [groupedLeads]);

  const moveBetweenContainers = useCallback(
    (activeContainer, overContainer, active, over, overId) => {
      const activeItems = items[activeContainer];
      const overItems = items[overContainer];
      const overIndex = overItems.indexOf(overId);
      const activeIndex = activeItems.indexOf(active.id);
      let newIndex;

      if (overId in items) {
        newIndex = overItems.length + 1;
      } else {
        const isBelowOverItem =
          over &&
          active.rect?.current?.translated &&
          active.rect?.current?.translated.top >= over.rect?.top + over.rect?.height;

        const modifier = isBelowOverItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }
      recentlyMovedToNewContainer.current = true;
      setItems(
        update(items, {
          [activeContainer]: {
            $splice: [[activeIndex, 1]],
          },
          [overContainer]: {
            $splice: [[newIndex, 0, active.id]],
            //$splice: [[newIndex, 0, items[activeContainer][activeIndex]],
          },
        }),
      );

      setMoveHistory({
        activeIndex,
        newIndex,
        overContainer,
        itemId: active.id,
      });
    },
    [items],
  );

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items,
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId !== null) {
        if (overId in items) {
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) => container.id !== overId && containerItems.includes(container.id),
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items],
  );
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        //distance: 5,
        delay: 100,
        tolerance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 5,
        delay: 0,
        tolerance: 5,
      },
    }),
  );

  const findContainer = (id) => {
    if (id in items) return id;
    console.log(id, containers)
    if (containers.includes(`column-${id}`)) return `column-${id}`
    return containers.find((key) => items[key].includes(id));
  };

  // region Drag events
  function handleDragStart({ active }) {
    setActiveId(active.id);
    setClonedItems(items);
  }

  function handleDragOver({ active, over }) {
    const overId = over?.id;
    if (!overId || active.id in items) return;
    const overContainer = findContainer(overId);
    const activeContainer = findContainer(active.id);

    if (!overContainer || !activeContainer) return;

    if (activeContainer !== overContainer) {
      moveBetweenContainers(activeContainer, overContainer, active, over, overId);
    }
  }

  function handleDragEnd({ active, over }) {
    if (!over) return reset();

    if (active.id in items && over?.id) return reset();

    const activeContainer = findContainer(active.id);

    if (!activeContainer) return reset();

    const overContainer = findContainer(over.id);

    // Bypass in a column move
    if (activeContainer === overContainer && isEmpty(moveHistory)) return reset();

    if (overContainer) {
      const activeIndex = items[activeContainer].indexOf(active.id);
      const overIndex = items[overContainer].indexOf(over.id);

      if (activeIndex !== overIndex) {
        setItems((items) => ({
          ...items,
          [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
        }));
      }

      leadDropAPICallHandler(active.id, overIndex, activeIndex);
    }

    reset();
  }

  const handleDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  // endregion

  function reset() {
    setActiveId(null);
    setMoveHistory({});
  }

  const leadDropAPICallHandler = (leadId, overIndex, activeIndex) => {
    let payload = null;
    if (!isEmpty(moveHistory)) {
      // Change status
      const column = findColumn(get(moveHistory, 'overContainer'));
      payload = {
        leadId,
        lead_state_id: column.id,
      };
    }
    if (!payload) return;

    dispatch({
      type: 'leads/edit',
      payload,
    });
  };
  const findColumn = (columnId: string) => columns.find((c) => 'column-' + c[groupId] === columnId);
  const getActiveColumnItem = () => findColumn(activeId);

  /**
   * By active, we mean the current dragged element which the shadow is visible
   * */
  const renderActiveElement = () => {
    if (!activeId) return null;

    const activeColumn = getActiveColumnItem();
    if (containers.includes(activeId))
      return (
        <LeadColumn
          id={activeId}
          leadIds={items[activeId]}
          name={activeColumn.title || activeColumn.label || activeColumn.name}
          color={getActiveColumnItem().color}
          dragOverlay
        />
      );

    return <LeadCard id={activeId} dragOverlay />;
  };

  if (loading && (columns || []).length === 0) return <SkeletonKanbanBoard />;

  return (
    <Spin spinning={loading}>
      <div className={classNames(styles.kanban, 'client-only-kanban')} id={'#kanban'}>
        <DndContext
          sensors={sensors}
          collisionDetection={collisionDetectionStrategy}
          measuring={{
            droppable: {
              strategy: MeasuringStrategy.WhileDragging,
            },
          }}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <div className={styles.kanbanContainer}>
            <SortableContext items={containers} strategy={horizontalListSortingStrategy}>
              {containers.map((containerId) => {
                const column = columns.filter((c) => 'column-' + c[groupId] === containerId)[0];
                if (!column) return null;
                return (
                  <LeadColumn
                    id={containerId}
                    key={containerId}
                    leadIds={items[containerId]}
                    name={column.label || column.title || column.name}
                    color={column.color}
                    isSortingContainer={isSortingContainer}
                    {...column}
                  />
                );
              })}
            </SortableContext>
          </div>
          <ClientOnlyPortal selector=".client-only-kanban">
            <DragOverlay>{renderActiveElement()}</DragOverlay>
          </ClientOnlyPortal>
        </DndContext>
      </div>
    </Spin>
  );
}
