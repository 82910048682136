// @ts-nocheck

  import AreaChartOutlined from '@ant-design/icons/es/icons/AreaChartOutlined';
import TeamOutlined from '@ant-design/icons/es/icons/TeamOutlined';
import ScheduleOutlined from '@ant-design/icons/es/icons/ScheduleOutlined';
import RobotOutlined from '@ant-design/icons/es/icons/RobotOutlined';
import FileDoneOutlined from '@ant-design/icons/es/icons/FileDoneOutlined';
import EnvironmentOutlined from '@ant-design/icons/es/icons/EnvironmentOutlined';
import PictureOutlined from '@ant-design/icons/es/icons/PictureOutlined';
import LineChartOutlined from '@ant-design/icons/es/icons/LineChartOutlined';
import NotificationOutlined from '@ant-design/icons/es/icons/NotificationOutlined';
import SettingOutlined from '@ant-design/icons/es/icons/SettingOutlined';
import UserOutlined from '@ant-design/icons/es/icons/UserOutlined';
import QuestionCircleOutlined from '@ant-design/icons/es/icons/QuestionCircleOutlined';
import CreditCardOutlined from '@ant-design/icons/es/icons/CreditCardOutlined'
  export default {
    AreaChartOutlined,
TeamOutlined,
ScheduleOutlined,
RobotOutlined,
FileDoneOutlined,
EnvironmentOutlined,
PictureOutlined,
LineChartOutlined,
NotificationOutlined,
SettingOutlined,
UserOutlined,
QuestionCircleOutlined,
CreditCardOutlined
  }