import * as React from 'react'
import {useEffect} from 'react'
import {connect, useModel} from 'umi'
import {Spin} from 'antd';

import {JourneyModel} from "@/typings/models/Journey";
import {DispatchFunction} from '@/typings/models/ReduxState';
import { getRoleDefaultRoute } from '@/typings/roleMapping';

interface RootProps {
  selectedJourney: JourneyModel;
  journeyList: JourneyModel[];
  history: History;
  config: any;
  dispatch: DispatchFunction;

}

const Root: React.FC<RootProps> = ({
  selectedJourney,
  history,
  journeyList,
  config,
  dispatch,
}: RootProps) => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const user = initialState?.currentUser?.User
  const isLoggedIn = Boolean(user)
  const userRole = user?.role;

  useEffect(() => {
    if (!isLoggedIn || !userRole?.role) history.push('/user/login');
    else {
      const journeyId = selectedJourney?.hashId ?? journeyList?.[0]?.hashId;
      if (journeyId) {
        if (!config?.[journeyId])
          dispatch({
            type: 'journey/select',
            payload: {
              journeyId: journeyId,
            },
          });
        history.push(getRoleDefaultRoute(userRole.role, journeyId));
      }
    }
  }, [selectedJourney, JSON.stringify(journeyList)]);
  useEffect(() => {
    setInitialState({
      ...initialState,
      config: { ...initialState?.config, ...config },
    });
  }, [JSON.stringify(config)]);

  return <Spin />;
};
export default connect(({ journey,user }) => ({
  selectedJourney: journey.selectedJourney,
  journeyList: journey.list,
  config: journey.config,

}))(Root);
