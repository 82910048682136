import React from 'react';
import {Empty, Space, Timeline} from 'antd';
import {ClockCircleOutlined} from '@ant-design/icons';
import get from "lodash/get";
import LeadName from "@/components/Lead/LeadName";
import PrettyDate from "@/components/PrettyDate";
import {LeadStatusPresentation} from "@/components/Filters/LeadStatusFilters";

const LeadActivity = ({leadDetails}) => {
  const activities = get(leadDetails, 'activities', [])

  const EVENT_TYPES = {
    lead_state_changed: 'lead_state_changed',
    assignee_changed: 'assignee_changed',
    created: 'created',
    branch_changed: 'branch_changed',
    customer_data_changed: 'customer_data_changed',
    archived: 'archived',
    unarchived: 'unarchived',
  }
  const EVENT_TYPE_MAP = {
    [EVENT_TYPES.lead_state_changed]: "Changed status",
    [EVENT_TYPES.assignee_changed]: "Assigned",
    [EVENT_TYPES.created]: "Created",
    [EVENT_TYPES.branch_changed]: "Changed branch",
    [EVENT_TYPES.customer_data_changed]: "Changed customer information",
    [EVENT_TYPES.archived]: "Archived",
    [EVENT_TYPES.unarchived]: "Unarchived",
  }

  const getValueComponent = (event, key) => {

    switch (event.activity_type){
      case EVENT_TYPES.lead_state_changed:
        return <LeadStatusPresentation status={event[key]}/>
      case EVENT_TYPES.assignee_changed:
        return <LeadName lead={{customer: event[key]}} readonly/>
      case EVENT_TYPES.branch_changed:
        return event[key]?.name
    }

    return get(event[key], 'label', get(event[key], 'name', get(event[key], 'title', event[key])))
  }

  if (activities.length === 0) return <Empty/>
  return (
    <Timeline>
      {activities.map((event, index) => (
        <Timeline.Item
          key={index}
          dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}
        >
          <Space direction={"vertical"}>
            <Space>
              <LeadName lead={{customer: event.editor}} readonly/>
              <span>{EVENT_TYPE_MAP[event.activity_type]}</span>
            </Space>
            <Space direction={"vertical"} style={{marginBottom: 5}}>
              {event.prev_value && (
                <Space>
                  <strong>Previous:</strong> {getValueComponent(event, 'prev_value')}
                </Space>
              )}
              {event.new_value &&
                <Space><strong>Next:</strong> {getValueComponent(event, 'new_value')}</Space>}
              <small><PrettyDate date={event.created}/></small>
            </Space>
          </Space>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export default LeadActivity;
