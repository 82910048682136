// @ts-nocheck

import AreaChartOutlined from '@ant-design/icons/AreaChartOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import ScheduleOutlined from '@ant-design/icons/ScheduleOutlined';
import RobotOutlined from '@ant-design/icons/RobotOutlined';
import FileDoneOutlined from '@ant-design/icons/FileDoneOutlined';
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';
import PictureOutlined from '@ant-design/icons/PictureOutlined';
import LineChartOutlined from '@ant-design/icons/LineChartOutlined';
import NotificationOutlined from '@ant-design/icons/NotificationOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import CreditCardOutlined from '@ant-design/icons/CreditCardOutlined'

export default {
  AreaChartOutlined,
TeamOutlined,
ScheduleOutlined,
RobotOutlined,
FileDoneOutlined,
EnvironmentOutlined,
PictureOutlined,
LineChartOutlined,
NotificationOutlined,
SettingOutlined,
UserOutlined,
QuestionCircleOutlined,
CreditCardOutlined
}
    