import { LeadReportItemModel } from "@/typings/models/LeadReportItem";
import { APPOINTMENT, CONVERSION_RATE, DEALS, REVENUE,LEADS } from "./constants";

// utils/exportToCSV.ts
export const exportToCSV = (headers: string[], data: any[], filename: string = 'data.csv', convertedRows = []) => {
  try {
    if (!Array.isArray(headers)) {
      throw new Error('Invalid input: headers should be an array');
    }
    if (!Array.isArray(data)) {
      throw new Error('Invalid input: data should be an array');
    }

    const rows = convertedRows?.length ? convertedRows : data.map(item => headers?.map(header => item[header]));
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\n';
    csvContent += rows.map(row => row.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error exporting to CSV:', error);
  }
};

export const transformLeadReportDataToBarChartFormat = (data:LeadReportItemModel[], key) => {
  let transformedData = [];
  data?.forEach((item) => {
    const { label, values } = item;
    const { revenue, count, conversion_rate } = values;
    typeof revenue === 'number' &&transformedData.push({ [key]: label?.title, category: REVENUE, value: revenue });
    typeof count === 'number'  && transformedData.push({ [key]: label?.title, category: LEADS, value: count });
    //transformedData.push({ [key]: label, category: APPOINTMENT, value: appointment });
    typeof conversion_rate === 'number'  && transformedData.push({ [key]: label?.title, category: CONVERSION_RATE, value: conversion_rate });
  });
  return transformedData;
};
const colorMap = {
  pink: '#FFC0CB',
  green: '#98FB98',
  orange: '#FFA500',
  red: '#FF4500',
  blue: '#0000FF',
  purple: '#800080',
  yellow: '#FFFF00',
  cyan: '#00FFFF',
  magenta: '#FF00FF',
  lime: '#00FF00',
};

const similarColorsMap = {
  pink: ['#FFC0CB', '#FFB6C1', '#FF69B4', '#FF1493', '#DB7093'],
  green: ['#98FB98', '#90EE90', '#8FBC8F', '#32CD32', '#228B22'],
  orange: ['#FFA500', '#FF8C00', '#FF7F50', '#FF6347', '#FF4500'],
  red: ['#FF4500', '#DC143C', '#B22222', '#8B0000', '#800000'],
  blue: ['#0000FF', '#0000CD', '#4169E1', '#4682B4', '#5F9EA0'],
  purple: ['#800080', '#8A2BE2', '#9400D3', '#9932CC', '#BA55D3'],
  yellow: ['#FFFF00', '#FFD700', '#FFA500', '#FF8C00', '#FF7F50'],
  cyan: ['#00FFFF', '#00CED1', '#20B2AA', '#48D1CC', '#40E0D0'],
  magenta: ['#FF00FF', '#FF00CC', '#FF0099', '#FF0066', '#FF0033'],
  lime: ['#00FF00', '#32CD32', '#228B22', '#006400', '#008000'],
};

export const generateGradientColors = (colorKey: keyof typeof colorMap, length: number): string[] => {
  const similarColors = similarColorsMap[colorKey];
  const colors = [];

  for (let i = 0; i < length; i++) {
    colors.push(similarColors[i % similarColors.length]);
  }

  return colors;
};

export const convertBreakdownApiResponseToFunnelData = (chartData = [], hasBreakdown = true, isByPercentage = false) => {
  if (!Array.isArray(chartData)) return;

  if (!hasBreakdown) {
    const labels = chartData?.map((item: any) => item.label);
    const values = chartData?.map((item: any) => item.values);
    const data = {
      labels,
      colors: [['#FFB178']],
      gradientDirection: 'horizontal',
      values: values.map(value => [value])
    };
    return data;
  }

  const labels = chartData?.map((item: any) => item.label);
  const subLabels = chartData[0]?.values?.map((item: any) => item.label);
  const values = chartData?.map((item: any) => {
    const stepTotal = item.values.reduce((sum: number, subItem: any) => sum + subItem.value, 0);
    let cumulativePercentage = 0;
    const percentages = item.values?.map((subItem: any, index: number, array: any[]) => {
      let subItemValue = isByPercentage ? (subItem.value / stepTotal) * 100 : subItem.value;
      if (isByPercentage) {
        subItemValue = parseFloat(subItemValue.toFixed(2)); // Round to 2 decimal places
        cumulativePercentage += subItemValue;
        // Adjust the last item to ensure the sum is exactly 100%
        if (index === array.length - 1) {
          subItemValue += 100 - cumulativePercentage;
        }
      }
      return subItemValue;
    });
    return percentages;
  });

  const colors = subLabels.map((_, index) => generateGradientColors(Object.keys(colorMap)[index] as keyof typeof colorMap, subLabels.length));

  const data = {
    labels,
    subLabels,
    colors: colors,
    gradientDirection: 'horizontal',
    values,
  };
  return data;
};