import * as React from "react"
import {useEffect} from "react"
import {Avatar} from "antd";
import {merge} from "lodash";

import {F} from "@/utils/intl";
import LeadDropdownFilter from "@/components/Filters/LeadDropdownFilter";
import {useDispatch, useIntl, useModel, useSelector} from "umi";

import * as LeadSelectors from "@/selectors/leadSelectors"
import {AssigneeModel} from "@/typings/models/Assignee";
import {UserDeleteOutlined} from "@ant-design/icons";
import {hasAccess} from "@/utils/access";
import {SALE_AGENT} from "@/typings/roleMapping";

const LeadAssigneeDropdownFilters = (props) => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const assignees = useSelector(LeadSelectors.selectLeadAssignees)
  const intl = useIntl()
  const dispatch = useDispatch()
  const assigneeFilter = useSelector(LeadSelectors.selectAssigneeFilter)

  const isSalesAgent = hasAccess(initialState.currentUser, SALE_AGENT)

  useEffect(() => {
    if (assignees.length === 0) {
      dispatch({
        type: 'leads/fetchAssignees'
      })
    }
  }, []);

  useEffect(() => {
    if (isSalesAgent && !assigneeFilter && assignees.length === 1){
      dispatch({
        type: 'leads/changeFilter',
        payload: {
          assignee: assignees[0].id
        }
      })
    }
  }, [assignees]);

  const optionRender = (assignee: AssigneeModel) => {
    const name = `${assignee.firstname} ${assignee.lastname}`
    const avatarProps = {}
    if (assignee.avatar_link) {
      avatarProps.src = assignee.avatar_link
    }
    return <div style={{display: 'flex', alignItems: 'center'}}>
      {assignee.unassigned ? <UserDeleteOutlined style={{marginRight: 10}}/> : <div style={{width: 24, height: 24, marginRight: 8}}>
        <Avatar {...avatarProps} size="small"
                style={{
                  marginRight: 8,
                  width: 24,
                  height: 24,
                  verticalAlign: "baseline"
                }}>{!assignee.avatar_link && name[0]}</Avatar>
      </div>}
      <span>
        {isSalesAgent && !assignee.unassigned && `(${intl.formatMessage({id: 'pages.lead.assignee.me', defaultMessage: 'Me'})}) `}
        {name}

      </span>
    </div>
  }

  const handleSelectionChange = (selectedValues) => {
    props.onSelectionChange && props.onSelectionChange(selectedValues);
    props.onChange && props.onChange(selectedValues);
  };

  const getOptions = () => {
    if (!props.showUnassigned) return assignees

    return assignees.concat({
      firstname: intl.formatMessage({id: 'pages.lead.assignee.unassigned', defaultMessage: 'Unassigned'}),
      lastname: '',
      unassigned: true,
      id: 'null'
    })
  }
  return <LeadDropdownFilter placeholder={<F id={"pages.leads.filters.assignee.placeholder"} defaultMessage={"Agent"}/>}
                             optionRender={optionRender}
                             selectProps={merge(props?.selectProps, {
                               style: {
                                 minWidth: 180
                               }
                             })}
                             filterKey={'assignee'} options={getOptions()}
                             onChange={handleSelectionChange}
                             {...props}
  />
}

export default LeadAssigneeDropdownFilters;
