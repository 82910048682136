import React, {useEffect, useState} from 'react';
import {useDispatch, useIntl, useModel, useSelector} from 'umi';
import MyPageContainer from "@/components/MyPageContainer";
import {Card} from "antd";
import * as LeadSelectors from "@/selectors/leadSelectors"
import LeadTasksOverview from "@/components/Lead/LeadTasks/LeadTasksOverview";
import {SOLUTION_KEYS} from "@/constants/solutions";
import {TasksTable} from "@/pages/Tasks/TasksTable";

const Tasks: React.FC = () => {
  const {initialState, setInitialState} = useModel('@@initialState');
  const [sortBy, setSortBy] = useState('priority');
  const loading = useSelector((state: DefaultRootState) => state.loading.effects['leads/fetchLeadsTasksList'])
  const intl = useIntl();

  const dispatch = useDispatch()

  const taskList = useSelector(LeadSelectors.selectTasks)

  const handleSortChange = (value) => {
    setSortBy(value);
  };
  useEffect(() => {

    setInitialState({
      ...initialState,
      selectedSolution: SOLUTION_KEYS.LEAD_MANAGEMENT
    })

    fetchTableData()
  }, []);
  function fetchTableData() {
      dispatch({
        type: 'leads/fetchLeadsTasksList',
      });
  }

  return (
    <MyPageContainer extraProps={{actions: [<LeadTasksOverview/>]}}>
      <Card>
        {/*<Space style={{paddingLeft: 20}}>*/}
        {/*  <div>*/}
        {/*    Sort by*/}
        {/*  </div>*/}
        {/*  <Select*/}
        {/*    defaultValue="priority"*/}
        {/*    style={{ width: 130, borderBottom:"2px dashed #aaa" }}*/}
        {/*    onChange={handleSortChange}*/}
        {/*    size={"small"}*/}
        {/*    bordered={false} // This makes it "ghost" (transparent)*/}
        {/*  >*/}
        {/*    <Option value="priority">Priority</Option>*/}
        {/*    <Option value="date">Date</Option>*/}
        {/*    <Option value="customer">Customer</Option>*/}
        {/*  </Select>*/}
        {/*</Space>*/}
        <TasksTable data={taskList} request={fetchTableData}/>
      </Card>

    </MyPageContainer>
  );
};

export default Tasks
