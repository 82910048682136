import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Select,
  Row,
  Col,
  Spin,
  Alert,
  Typography,
  Form,
  message,
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import * as LeadSelectors from "@/selectors/leadSelectors";
import { useSelector, useDispatch } from 'umi';
import { transformObjectToPayloadFields } from './utils';
import isEmpty from 'lodash/isEmpty';

const { Text } = Typography;

const MappingConfigurationStep = ({
  isNew,
  config,
  handleMappingSubmit,
  onClose,
  prevStep,
}) => {
  const [form] = Form.useForm();
  const { userBranches } = useSelector(({ location: { userBranches } }) => ({
    userBranches,
  }));
  const { systemFields, leadSourceDetail } = useSelector((state: DefaultRootState) => ({
    systemFields: LeadSelectors.selectLeadFields(state),
    leadName: state.journey?.selectedJourney?.name,
    leadSourceDetail: state.leads.leadSourceDetail?.lead_source,
  }));
  const dispatch = useDispatch();

  const [payloadFields, setPayloadFields] = useState([]);
  const [isPolling, setIsPolling] = useState(false);
  const intervalIdRef = useRef(null); // Use useRef to store intervalId
  const fetchLeadConfig = (id) => {
    dispatch({
      type: 'leads/fetchLeadSource',
      payload: { id },
    });
  };
  
  useEffect(() => {
    return () => {
      if (isPolling && intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [isPolling]);
  useEffect(() => {
    if (isEmpty(leadSourceDetail?.configuration?.last_payload)) {
      if (leadSourceDetail?.id) startPolling(leadSourceDetail?.id);
    }
  }, [JSON.stringify(leadSourceDetail)]);
  const startPolling = (id) => {
    setIsPolling(true);
    intervalIdRef.current = setInterval(() => {
      fetchLeadConfig(id);
      if (!isEmpty(leadSourceDetail?.configuration?.last_payload)) {
        clearInterval(intervalIdRef.current);
        setIsPolling(false);
        message.success('You have sent the data successfully');
      }
    }, 10000); // Poll every 5 seconds
  };
  useEffect(() => {
    if (!isNew && leadSourceDetail?.configuration?.last_payload) {
      const fields = transformObjectToPayloadFields(leadSourceDetail?.configuration?.last_payload);
      setPayloadFields(fields);
    }
  }, [isNew, config, form]);
  useEffect(() => {
    if (!isNew && config) {
      const mappings = config?.configuration?.mappings?.reduce((acc, mapping) => {
        acc[mapping.target_field] = mapping.sources;
        return acc;
      }, {});
      form.setFieldsValue({ ...mappings });
    }
  }, [isNew, config, form]);
  return (
    <>
      {isEmpty(leadSourceDetail?.configuration?.last_payload) && (
        <>
          <Row justify="center" style={{ marginTop: 24 }}>
            {leadSourceDetail?.configuration?.webhook_url && (
              <Alert
                style={{ backgroundColor: '#eaffea' }}
                icon={<CheckCircleOutlined />}
                message={
                  !isNew && isEmpty(leadSourceDetail?.configuration?.last_payload)
                    ? 'The webhook is created but you have not posted any sample data to proceed to mapping'
                    : 'Successfully created webhook'
                }
                description={
                  <>
                    <Text>Webhook URL: </Text>
                    <Text copyable style={{ marginLeft: 10 }}>
                      {leadSourceDetail?.configuration?.webhook_url}
                    </Text>{' '}
                  </>
                }
                type="info"
                showIcon
              />
            )}
          </Row>
          <Row justify="center" style={{ marginTop: 24 }}>
            <Form.Item>
              <Button type="default" onClick={onClose} style={{ margin: '0 8px' }}>
                Later
              </Button>
            </Form.Item>
          </Row>
        </>
      )}
      {isPolling && isEmpty(leadSourceDetail?.configuration?.last_payload) && (
        <Row justify="center" style={{ marginTop: 24 }}>
          <Spin tip="Checking you sent any data or not..." />
          <Typography.Text style={{ marginLeft: 10 }} type="secondary">
            Polling every 10 seconds to check if you have sent any data{' '}
          </Typography.Text>
        </Row>
      )}
      {!isEmpty(leadSourceDetail?.configuration?.last_payload) && (
        <Form layout="vertical" form={form} onFinish={handleMappingSubmit}>
          <Row gutter={16} style={{ marginBottom: 24 }}>
            {(systemFields?.default || [])?.map((field) => (
              <Col span={12} key={field}>
                <Form.Item
                  key={field.key}
                  label={field.label}
                  name={field.key}
                  rules={[{ required: field.required, message: `${field.label} is required` }]}
                  style={{ marginBottom: 20 }}
                  required={field.required}
                  tooltip={field.tooltip}
                >
                  <Select
                    mode="multiple"
                    placeholder={`Select a field for "${field.label}"`}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {payloadFields?.map((field) => (
                      <Option key={field.key} value={field.key}>
                        {field.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ))}
          </Row>
          <Form.Item>
            <Button type="default" onClick={onClose} style={{ margin: '0 8px' }}>
              Cancel
            </Button>
            <Button style={{ margin: '0 8px' }} onClick={prevStep}>
              Previous
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default MappingConfigurationStep;
